import { useState } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card, Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components
import Page from '../components/Page'; 
import {ContactForm, ContactInfo} from '../sections/support';
import Navbar from '../components/Navbar';
import Iconify from '../components/Iconify';
// sections
 

// ----------------------------------------------------------------------
const FORM_ENDPOINT = ""; // TODO - fill on the later step

export default function Support() {
  const theme = useTheme();

  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };


  return (
    <Page title="Support" sx={{minHeight:'100vh'}}>
            <Grid container justifyContent={'space-evenly'} columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>
                <Grid container 
                    padding={1.5} 
                    flexDirection={'row'} 
                    alignItems={'center'} 
                    justifyContent={'flex-start'} 
                    onClick={() => {
                        navigate('/menu');
                    }}>
                        <Iconify icon={'eva:chevron-left-outline'} sx={{width: 24, height: 24}} />
                        <Typography variant="body1"> Go Back </Typography>    
                </Grid>
                <Grid item container lg={10} sm={12} md={9} xl={10} borderRadius={1} spacing={3} sx={{margin:'0!important',width:'100%!important'}}>
                    
                <Container maxWidth="xl" height="100vh">
                  <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Typography variant={'h3'}>
                            {"Contact Us"}
                        </Typography>
                  </Grid>
                  <Grid sx={{padding:5}}>
                    <ContactInfo />
                    <Box margin={5} />
                    <ContactForm />
                  </Grid>
          
                </Container>
            </Grid>
          </Grid>
    </Page>
  );
}
