 // @mui
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Box, Button, Card, Grid, Icon, Modal, Typography } from '@mui/material'; 
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import { getSelectedAccountType, getSelectedCorporate } from '../../store/otp/reducer';
import { fetchMenu } from '../../data/network/menu/api';
import { updateCartApi } from '../../data/network/cart/api';
import { formatDate } from '../../utils/date';
import { ACCOUNT_TYPE, SNACKBAR_SEVERITY } from '../../constants/app_constants';
import { getItemList, resetCustomizeItem, setCustomizeItem } from '../../store/menu/reducer';
import { getItemQty } from '../../store/cart/reducer';
import Iconify from '../../components/Iconify';
import CustomizeOption from './CustomizeOption';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/layout/reducer';
import CustomizeList from './CustomizeList';
  // components
   
  // ----------------------------------------------------------------------
   
MenuItemCard.propTypes = {
    item: PropTypes.object,
    // ingredients: PropTypes.object,
    // img: PropTypes.object,
    // title: PropTypes.string.isRequired,
    // scheduleId: PropTypes.string.isRequired,
    // price: PropTypes.string.isRequired,
    sx: PropTypes.object,
};
  
export default function MenuItemCard({ item, sx, i, ...other }) {
 
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const selectedAccountType = useSelector(state => getSelectedAccountType(state));
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));
    const menuListOptions = useSelector(state => getItemList(state));
    const qty = useSelector(state => getItemQty(state, item ));

    const [isCustomizeOptionModalVisible, setIsCustomizeOptionModalVisible] = useState(false);
    const [isCustomizeListModalVisible, setIsCustomizeListModalVisible] = useState(false);

    const [isItemDetailModalVisible, setIsItemDetailModalVisible] = useState(false);


    const handleItemQtyChange = (  item, qty ) => {
        if (qty <= item.max_quantity){
            updateCartApi(dispatch, cookies, {              
                'item': item, 
                'quantity': qty,
                'addon' : []
            });
        }else{
            const message = `You can order a maximum of ${item.max_quantity} units at a time.`;        
            dispatch(setSnackbarMessage({'message': message, 'severity': SNACKBAR_SEVERITY.INFO}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
        }
    }

    const handleCustomizeOptionModalClose = () => {
        setIsCustomizeOptionModalVisible(false);
        dispatch(resetCustomizeItem());
    }

    const showCustomizeOptionModal = (item) => {
        setIsCustomizeOptionModalVisible(true);
    }

    const handleCustomizeListModalClose = () => {
        setIsCustomizeListModalVisible(false);
    }

    const showCustomizeListModal = (item) => {
        setIsCustomizeListModalVisible(true);
    }

    const handleItemDetailModalClose = () => {
        setIsItemDetailModalVisible(false);
    }

    const showItemDetailModal = (item) => {
        setIsItemDetailModalVisible(true);
    }

    
    
    const CustomizeListHeader = () => {
        return (
            <></>
        );
    }
    
    const CustomizeListFooter = () => {
        return (
            <></>
        );
    }

    return (
         
        <Grid key={i} className="menu-item-card-container">
            <CustomizeOption item={item} isOpen={isCustomizeOptionModalVisible} handleClose={handleCustomizeOptionModalClose} />
            <CustomizeList
                item={item}
                isOpen={isCustomizeListModalVisible}
                handleClose={handleCustomizeListModalClose}
                showCustomizeOption={showCustomizeOptionModal}
                handleCustomizeOptionClose={handleCustomizeOptionModalClose}
            />

            <div className="wrapper menu-item-card-main-web">
                <div className="container">
                    <div className="top">
                        {
                            item.image_url == null &&
                            <img src='/static/meal.png' alt={item.title} height={100} width={'100%'} style={{borderRadius:0}}/> 
                        }
                        {   
                            item.image_url != null &&
                            <img src={item.image_url} alt={item.title}  height={100} width={'100%'} style={{borderRadius:0}}/> 
                        } 
                    </div>
                    <div className="bottom">
                        
                        <Grid className="left" paddingTop={1} paddingLeft={1} paddingRight={1}>

                            <span>
                                {
                                    item.is_non_veg
                                    ? <img height={16} width={16} src='/static/nonveg.png' alt='non veg' />
                                    : <img height={16} width={16} src='/static/veg.png' alt='veg' />
                                }
                            </span>

                            <Typography className="menu-item-display-name-web" variant="h6">
                                <span>{item.display_name}</span>
                            </Typography>
                            {/* <div className="details">
                               
                                
                            </div> */}
                            {/* <div className="buy"><i className="material-icons">add_shopping_cart</i></div> */}
                        </Grid>
                        <Grid container flexDirection={'row'} item paddingLeft={1} paddingRight={1}>
                            {
                                (item.effective_price !== item.marked_price) && 
                                <Typography variant="h5" sx={{textDecoration:'line-through', paddingRight:1}}>{`₹ ${item.marked_price}`}</Typography>
                            }
                            <Typography  variant="h5">{`₹ ${item.effective_price}`}</Typography>
                        </Grid>
                        {/* <div className="right">
                            <div className="done"><i className="material-icons">done</i></div>
                            <div className="details">
                            <h1>Chair</h1>
                            <p>Added to your cart</p>
                            </div>
                            <div className="remove"><i className="material-icons">clear</i></div>
                        </div> */}

                        <Grid item container justifyContent="center" alignItems="center" direction="column" flexWrap="wrap">  
                            
                            <Grid item container className='menu-item-price-action' justifyContent="center" alignItems="center">
                                {
                                    qty === 0 && 
                                    <Button variant="contained" onClick={() => { 
                                        
                                        if(item.addons.length > 0 ){
                                            dispatch(setCustomizeItem({'item': item}));
                                            showCustomizeOptionModal(item);
                                        }else{
                                            handleItemQtyChange(item , qty+1);
                                        }
                                    }} sx={{fontWeight:400, fontSize:'0.8rem'}}>
                                        Add
                                    </Button>
                                }
    
                                {
                                    qty > 0 && 
                                    <div style={{width:'50%'}}>
                                        <Grid container spacing={0}
                                            direction="row"
                                            justifyContent="center"
                                            flexWrap={"nowrap"}
                                            alignItems="center"
                                            sx={{padding:0}}
                                            className="change_qty"
                                            >
                                            <Grid item sx={{padding:0}}>
                                                <Button className="shopping__icon" onClick={() => {
                                                    if(item.addons.length > 0 ){
                                                        showCustomizeListModal(item);
                                                    }else{
                                                        handleItemQtyChange( item , qty-1);
                                                    }
                                                }}>
                                                    <Iconify icon="fa:minus" color="#fff" />
                                                </Button> 
                                            </Grid>
                                            <Grid item sx={{justifyContent:'center',paddingLeft:2, paddingRight: 2}}>
                                                <Typography variant="body2" color="text.secondary" style={{color:'#fff'}}>
                                                    {qty}
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{padding:0}}>
                                                <Button className="shopping__icon" onClick={(e) => {
                                                    if(item.addons.length > 0 ){
                                                        showCustomizeListModal(item);
                                                    }else{
                                                        handleItemQtyChange( item , qty+1);
                                                    }
                                                }}>
                                                    <Iconify icon="fa:plus" color="#fff" /> 
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            </Grid>
                            {
                                item.addons.length > 0 &&
    
                                <div>
                                    <span style={{fontSize:'0.8rem'}}>Customisable</span>
                                </div>
                            }
                        </Grid>
                    </div>
                </div>
                {/* <div className="inside">
                    <div className="icon"><i className="material-icons">info_outline</i></div>
                    <div className="contents">
                    <table>
                        <tr>
                        <th>Width</th>
                        <th>Height</th>
                        </tr>
                        <tr>
                        <td>3000mm</td>
                        <td>4000mm</td>
                        </tr>
                        <tr>
                        <th>Something</th>
                        <th>Something</th>
                        </tr>
                        <tr>
                        <td>200mm</td>
                        <td>200mm</td>
                        </tr>
                        <tr>
                        <th>Something</th>
                        <th>Something</th>
                        </tr>
                        <tr>
                        <td>200mm</td>
                        <td>200mm</td>
                        </tr>
                        <tr>
                        <th>Something</th>
                        <th>Something</th>
                        </tr>
                        <tr>
                        <td>200mm</td>
                        <td>200mm</td>
                        </tr>
                    </table>
                    </div>
                </div> */}
            </div>


            <Grid className="menu-item-card-main" container item >  
                <Grid item justifyContent="center" alignItems="center">  
                    <Grid container justifyContent="center">
                        {
                            item.image_url == null &&
                            <img src='/static/meal.png' alt={item.title} height={64} width={96} style={{borderRadius:10}}/> 
                        }
                        {   
                            item.image_url != null &&
                            <img src={item.image_url} alt={item.title}  height={64} width={96} style={{borderRadius:10}}/> 
                        } 
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" direction="column" flexWrap="wrap">  
                            
                        <Grid item container className='menu-item-price-action' justifyContent="center" alignItems="center">
                            {
                                qty === 0 && 
                                <Button variant="contained" onClick={() => { 
                                    
                                    if(item.addons.length > 0 ){
                                        dispatch(setCustomizeItem({'item': item}));
                                        showCustomizeOptionModal(item);
                                    }else{
                                        handleItemQtyChange(item , qty+1);
                                    }
                                }} sx={{fontWeight:400, fontSize:'0.8rem'}}>
                                    Add
                                </Button>
                            }

                            {
                                qty > 0 && 
                                <div style={{width:'50%'}}>
                                    <Grid container spacing={0}
                                        direction="row"
                                        justifyContent="center"
                                        flexWrap={"nowrap"}
                                        alignItems="center"
                                        sx={{padding:0}}
                                        className="change_qty"
                                        >
                                        <Grid item sx={{padding:0}}>
                                            <Button className="shopping__icon" onClick={() => {
                                                if(item.addons.length > 0 ){
                                                    showCustomizeListModal(item);
                                                }else{
                                                    handleItemQtyChange( item , qty-1);
                                                }
                                            }}>
                                                <Iconify icon="fa:minus" color="#fff" />
                                            </Button> 
                                        </Grid>
                                        <Grid item sx={{justifyContent:'center',paddingLeft:2, paddingRight: 2}}>
                                            <Typography variant="body2" color="text.secondary" style={{color:'#fff'}}>
                                                {qty}
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={{padding:0}}>
                                            <Button className="shopping__icon" onClick={(e) => {
                                                if(item.addons.length > 0 ){
                                                    showCustomizeListModal(item);
                                                }else{
                                                    handleItemQtyChange( item , qty+1);
                                                }
                                            }}>
                                                <Iconify icon="fa:plus" color="#fff" /> 
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        </Grid>
                        {
                            item.addons.length > 0 &&

                            <div>
                                <span style={{fontSize:'0.8rem'}}>Customisable</span>
                            </div>
                        }
                    </Grid>
                </Grid>
                <Grid item container direction="column" justifyContent='space-evenly' className='menu-item-card-detail'>  
                    <Grid item container flexDirection={'row'} alignItems={'flex-start'} > 
                         
                        <Typography variant="h5" style={{ wordWrap: 'normal', wordBreak: 'break-word', maxWidth:'90%'}}>
                            <span>
                                {
                                    item.is_non_veg
                                    ? <img height={16} width={16} src='/static/nonveg.png' alt='non veg' />
                                    : <img height={16} width={16} src='/static/veg.png' alt='veg' />
                                }
                            </span> 
                            <span>{item.display_name}</span>
                        </Typography>
                    </Grid>

                    <Grid container flexDirection={'row'} item marginLeft={1}>
                        {
                            (item.effective_price !== item.marked_price) && 
                            <Typography variant="h5" sx={{textDecoration:'line-through', paddingRight:1}}>{`₹ ${item.marked_price}`}</Typography>
                        }
                        <Typography  variant="h5">{`₹ ${item.effective_price}`}</Typography>
                    </Grid>
                    
                    {item.ingredients.length > 1 && item.ingredients[0] !== "NA" && 
                        <Grid item sx={{ minHeight:'0vh' }}  marginTop={1}>
                            {item.ingredients.join(", ")}
                        </Grid>
                    }
                    
                </Grid>
            </Grid>
        </Grid> 
    );


}



  