import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// material
import { Stack,Box, TextField, IconButton, InputAdornment, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../components/Iconify';
import {getUser} from '../../store/user/reducer';

// ----------------------------------------------------------------------

export default function ContactInfo() {
  const navigate = useNavigate(); 

  return (
         <Grid container spacing={3} justifyContent="center" direction="row" padding={4}>
          <Grid container direction='column' spacing={4}>
            <Grid item container direction='column' spacing={2}>
                <Typography style={{fontSize:'1.2rem'}}>Get in touch!</Typography>
            </Grid> 
            <Grid item container direction='column' spacing={2}>
                <Typography variant='body1'>ByteRight Technologies Pvt Ltd</Typography>
            </Grid> 
            <Grid item container direction='column' spacing={2}>
                <Typography variant='body1'>80/2, 1st Main Road, Muneshwar Nagar, Sector 6, HSR Layout, Bengaluru, Karnataka – 560102</Typography>
            </Grid> 
            <Grid item container direction='column' spacing={2}>
                <Typography variant='body1'>Email : support@the-bytes.com</Typography>
            </Grid> 
            <Grid item container direction='column' spacing={2}>
                <Typography variant='body1'>Mobile: +91 7978901253</Typography>
            </Grid> 
          </Grid> 
        </Grid> 
  );
}
