import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { API_SAVE_QUERY } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { SEND_QUERY } from '../../../constants/message_constants';

export const saveQuery = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_SAVE_QUERY}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){ 
            dispatch(setSnackbarMessage({message: SEND_QUERY.SUCCESS ,severity:SNACKBAR_SEVERITY.SUCCESS}))
            dispatch(setSnackbarVisibilityStatus({status: true}));
          }else{
            dispatch(setSnackbarMessage({message: SEND_QUERY.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
            dispatch(setSnackbarVisibilityStatus({status: true}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({status: false}));  
          dispatch(setSnackbarMessage({message: SEND_QUERY.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
          dispatch(setSnackbarVisibilityStatus({status: true}));
        // console.log(response);
        });
    }else{
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({message: SEND_QUERY.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
        dispatch(setSnackbarVisibilityStatus({status: true}));
    }
}