 // @mui
 import { useCookies } from 'react-cookie';
 import { useEffect, useState } from 'react';
 import PropTypes from 'prop-types';
 import { alpha, styled } from '@mui/material/styles';
 import { Box, Button, Card, Grid, Typography } from '@mui/material'; 
 import { useDispatch, useSelector } from 'react-redux';
 
 
 import { getSelectedAccountType, getSelectedCorporate } from '../../store/otp/reducer';
 import { fetchMenu } from '../../data/network/menu/api';
 import { updateCartApi } from '../../data/network/cart/api';
 import { formatDate } from '../../utils/date';
 import { ACCOUNT_TYPE } from '../../constants/app_constants';
 import { getItemList, getMenuItem, resetCustomizeItem, setCustomizeCartItemId, setCustomizeItem } from '../../store/menu/reducer';
 import { getItemQty } from '../../store/cart/reducer';
 import Iconify from '../../components/Iconify';
import CustomizeOption from './CustomizeOption';
   // components
    
   // ----------------------------------------------------------------------
    
   CartItemCard.propTypes = {
     item: PropTypes.object,
     // ingredients: PropTypes.object,
     // img: PropTypes.object,
     // title: PropTypes.string.isRequired,
     // scheduleId: PropTypes.string.isRequired,
     // price: PropTypes.string.isRequired,
     sx: PropTypes.object,
 };
   
 export default function CartItemCard({ item, sx, ...other }) {
  
     const dispatch = useDispatch();
     const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']); 
     const qty = useSelector(state => getItemQty(state, item ));

     const [addonString, setAddonString] = useState('');
     const [isCustomizeOptionModalVisible, setIsCustomizeOptionModalVisible] = useState(false);

     const menuItem = useSelector(state => getMenuItem(state, item.schedule_id));
 
     const handleItemQtyChange = (item, qty ) => {
        console.log(item);
        const addonList = []
        item.addons.forEach(addon => {
            addonList.push({
                'addon' : addon,
                'quantity' : addon.quantity
            })
        })

        console.log(addonList);

        updateCartApi(dispatch, cookies, {
            'cart_item_id': item.cart_item_id,
            'item' : item,
            'quantity': qty,
            'addon' : addonList
        });

     }

     const handleCustomizeOptionModalClose = () => {
        setIsCustomizeOptionModalVisible(false);
        dispatch(resetCustomizeItem());
    }

    const showCustomizeOptionModal = (item) => {
        setIsCustomizeOptionModalVisible(true);
    }



    useEffect(() => {
        const addonList = [];
        if(item.addons && item.addons.length > 0){
            item.addons.forEach( addon => { 
                addonList.push(`${addon.display_name} (x${addon.quantity})`)
            })

            setAddonString(addonList.join(','));
        }
    },[item]);
  
    return (
         
        <Grid container className={(item.is_valid) ? `valid-cart-item` : `invalid-cart-item` } item direction="column" justifyContent="center" alignItems="center"  sx={{  py:2}} >  
            <CustomizeOption item={item} isOpen={isCustomizeOptionModalVisible} handleClose={handleCustomizeOptionModalClose} />
            <Grid item container xs={12} lg={12} xl={12} md={12} sm={12} paddingLeft={1.5} paddingRight={1.5}>                        
                <Grid xs={11} lg={11} xl={11} md={11} sm={11}>
                    {/*  */}
                </Grid>
                <Grid className='glass-box' item container borderRadius={10} justifyContent="center" alignItems="center" direction="row" lg={1} xl={1} md={1} sm={1} xs={1}>  
                    {/* <Button variant="contained" onClick={() => { handleItemQtyChange( item , 0) }}>
                        Remove
                    </Button> */}
                    <Iconify icon={'eva:close-outline'} color={'#000'} sx={{height: '18px'}} />
                </Grid>
            </Grid>
            <Grid container item direction={'row'} justifyContent={'space-around'} alignItems={'center'}>
                <Grid item container direction="row" justifyContent={'flex-start'} alignItems={'center'} width={'50px'} > 
                    <span>
                        {
                            item.image_url == null &&
                            <img src='/static/meal.png' alt={item.display_name} width={50} style={{borderRadius:20}}/> 
                        }
                        {
                            item.image_url != null &&
                            <img src={item.image_url} alt={item.display_name} width={50} style={{borderRadius:20}}/> 
                        }
                    </span>
                    {/* <Typography variant="h5">{item.display_name}</Typography> */}
                </Grid>
                <Grid item container direction="column" spacing={1} style={{width:'75%'}}> 

                        <Grid item container direction='row' justifyContent={'flex-end'}>
                            {/* {
                                (new Date(item.schedule_date).setHours(0, 0, 0, 0)  === new Date().setHours(0, 0, 0, 0) ) 
                                ? <span style={{fontSize:'0.8rem'}}> {
                                    `Today`}  </span>
                                : <span style={{fontSize:'0.8rem'}}>{` Scheduled for ${formatDate(item.schedule_date, "DD MMM ")} `}</span>
                            }  */}

                            

                            {/* <Grid className='glass-box' item container justifyContent="center" alignItems="center" direction="row" lg={1} xl={1} md={1} sm={1} xs={1}>  
                                <Iconify icon={'eva:close-outline'} color={'#000'} sx={{height: '24px'}} />
                            </Grid> */}
                            
                        </Grid>

                        <Grid item container direction='row' justifyContent={'flex-start'}>
                            <Typography variant="h6">{item.display_name} </Typography>                            
                        </Grid>
                        
                        <Grid container item justifyContent="space-between" alignItems="center" direction="row"> 
                            {/* <Typography variant="h5" style={{width:'70%'}}>{item.display_name} </Typography> */}
                            <Grid className='' item container justifyContent="center" alignItems="center" direction="row" lg={1} xl={1} md={1} sm={1} xs={1}>  
                                <span>
                                    {
                                        item.is_non_veg
                                        ? <img height={16} width={16} src='/static/nonveg.png' alt='non veg' />
                                        : <img height={16} width={16} src='/static/veg.png' alt='veg' />
                                    }
                                </span> 
                            </Grid>
                            <Grid container item flexDirection={'row'} justifyContent={'flex-end'} md={4} xs={4} lg={4} xl={4} sm={4}>
                                <Typography variant="h6"> {`₹${item.effective_price}`}</Typography>
                                {
                                    item.marked_price && item.effective_price !== item.marked_price &&
                                    <Typography variant="h6" sx={{textDecoration:'line-through', color: '#0c0c0c', paddingLeft:'4px'}}> {`₹${item.marked_price}`}</Typography>
                                }
                            </Grid>
                        </Grid>

                        {
                            item.addons && item.addons.length > 0 && <Grid container item>
                                <Typography variant="subtitle1" >Addon : {addonString}</Typography>                            
                            </Grid>
                        }
                        
                        {
                            (!item.is_valid)
                            ? <Grid item> 
                                <Box margin={1} />
                                <Typography sx={{'color': "red", fontSize:'0.7rem'}}>{`${item.invalid_message }`}</Typography>
                                <Box margin={1} />
                            </Grid>
                            : null
                        }
                        
                        {
                            (item.is_valid)
                            ? <Grid item container justifyContent="flex-start" alignItems="center" direction="row">  
                                
    
                                {
                                    qty === 0 && 
                                    <Button variant="contained" onClick={() => { handleItemQtyChange( item , qty+1) }}>
                                        Add
                                    </Button>
                                }
    
                                {
                                    qty > 0 && 
                                    <Grid container flexDirection={'row'} justifyContent={'space-between'}>
                                        <Grid container spacing={0}
                                            direction="row"
                                            xs={4} lg={4} xl={4} md={4} sm={4}
                                            justifyContent="start"
                                            flexWrap={"nowrap"}
                                            alignItems="center"
                                            sx={{padding:0, maxWidth:'50%!important',background: '#e86826', borderRadius: '4px',}}
                                            className="change_qty"
                                        >
                                            <Grid item sx={{padding:0}}>
                                                <Button className="shopping__icon" onClick={() => {
                                                handleItemQtyChange( item , item.quantity-1);   
                                                    }}
                                                >
                                                    <Iconify icon="fa:minus" color="#fff" />
                                                </Button> 
                                            </Grid>
                                            <Grid item sx={{justifyContent:'center',paddingLeft:2, paddingRight:2}}>
                                                <Typography variant="body2" color="#fff">
                                                    {item.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{padding:0}}>
                                                <Button className="shopping__icon" onClick={(e) => {
                                                    handleItemQtyChange( item , item.quantity+1);
                                                }}>
                                                    <Iconify icon="fa:plus" color="#fff" /> 
                                                </Button>
                                            </Grid>
                                    </Grid>
                                    
                                    {
                                        item.addons && item.addons.length > 0 && <Grid item container sx={{maxWidth:'125px', borderRadius:1, borderWidth:1, borderColor: '#e0e0e0'}}
                                            onClick={() => {
                                                dispatch(setCustomizeCartItemId({'cartItemId' : item.cart_item_id}));
                                                const addonList = []
                                                item.addons.forEach(addon => {
                                                    addonList.push({
                                                        'addon' : addon,
                                                        'quantity' : addon.quantity
                                                    })
                                                })
                                                console.log(menuItem);
                                                dispatch(setCustomizeItem({'item': menuItem, 'addons': addonList, 'quantity': item.quantity}));
                                                setIsCustomizeOptionModalVisible(true);
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Tap to customize
                                            </Typography>

                                            {/* <Iconify icon={'eva:arrow-ios-forward-outline'} color='#000' sx={{fontSize:'16px'}} /> */}
                                            {/* <Iconify icon="eva:edit-outline" sx={{width:18, height:18}} /> */}
                                            {/* <span>Customize</span> */}
                                        </Grid>
                                    }
                                </Grid>
                            }
                            </Grid>
                            :<Grid item container justifyContent="flex-start" alignItems="center" direction="row">  
                                <Button variant="contained" onClick={() => { handleItemQtyChange( item , 0) }}>
                                    Remove
                                </Button>
                            </Grid>
                        }
                </Grid>
            </Grid>
        </Grid> 
    );
}
   