import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox,Grid, TextField, IconButton, Box, InputAdornment, FormControlLabel, Container, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";

import { getOTPSentStatus, getOTPVerificationStatus, 
   getClientId, getClientSecret,setSelectedAccountType,setSelectedCorporate, 
   clearOTPState, getLoggedinUserId, getSelectedAccountType, getSelectedCorporate } from '../../../store/otp/reducer'; 
import { sendOTP,  verifyOTP,  } from '../../../data/network/otp/api';
import { generateAccessToken, getSession } from '../../../data/network/session/api';
import { getLoginStatus, getUser, getUserId } from '../../../store/user/reducer';

// component
import Iconify from '../../../components/Iconify';
import { ACCOUNT_TYPE, ROLES } from '../../../constants/app_constants';
import { fetchUserDetails } from '../../../data/network/user/api';
import { OTP_ERROR } from '../../../constants/message_constants';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showOTPContainer, setShowOTPContainer] = useState(false);
  const [otpValue, setOTPValue] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();

  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //   password: Yup.string().required('Password is required'),
  // });

  const phoneRegExp = /^((\\+[5-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  
  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(10, "Phone number is too short")
    .max(10, "Phone number is too long"),
    // email('Please enter valid mobile number').required('Mobile number is required'),
    otp: Yup.string() 
    .matches(/^[0-9]{4}$/gi, "Enter valid OTP")
    .length(4, 'Enter valid OTP')
    .required('OTP is required')
    // .min(4, "OTP is too short")
    // .max(4, "OTP is too long"), 
  });

  const formik = useFormik({
    initialValues: {
      mobile:  cookies.mobile ,
      otp: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {

      // call verify OTP Api here

      // navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const otpSent = useSelector((state) => getOTPSentStatus(state));
  const otpVerified = useSelector((state) => getOTPVerificationStatus(state));
  const userId = useSelector((state) => getLoggedinUserId(state));

  const clientId = useSelector((state) => getClientId(state));
  const clientSecret = useSelector((state) => getClientSecret(state));
  const isLoggedIn = useSelector((state) => getLoginStatus(state));
  const userDetails = useSelector((state) => getUser(state));
  const selectedAccountType = useSelector((state) => getSelectedAccountType(state));
  const selectedCorporate = useSelector((state) => getSelectedCorporate(state));

  useEffect(() => {
    dispatch(clearOTPState());
    getSession(dispatch, cookies, removeCookie); 
  },[]);

  useEffect(() => {
    if(otpSent){
      setShowOTPContainer(true);
    }else{
      setShowOTPContainer(false);
    }
  },[otpSent]);

  useEffect(() => {
    if(otpVerified){
      generateAccessToken(dispatch, cookies, setCookie, userId, clientId, clientSecret);
    }
  },[otpVerified]);

  useEffect(() => {
    if(isLoggedIn){
      fetchUserDetails(dispatch, cookies);
    }
  },[isLoggedIn]);

  const handleSendOTPClick = () => {
    
    if( errors.mobile || (cookies.mobile && cookies.mobile.length !== 10)){
      // show snackbar with error message
      displayMessage("error", OTP_ERROR.INVALID_MOBILE)  
    }else{ 
        // call send OTP Api here
     sendOTP(dispatch, cookies, {
        mobile: cookies.mobile,
        user_type: "CORPORATE",
        corporate_client_id: selectedCorporate.id
      }); 
    }
  }

  const handleVerifyOTPClick = () => {
    // call verify OTP Api here 

    if( errors.otp || cookies.mobile.length !== 10 || otpValue.length !== 4){
       // show snackbar with error message
      displayMessage("error", OTP_ERROR.INVALID_OTP)  
    }else{ 
      // call send OTP Api here
      verifyOTP(dispatch, cookies, {
        'mobile': cookies.mobile,
        'otp': otpValue
      });
    }
  }

  const displayMessage = (type, message ) => {
    // show Snackbar
    dispatch(setSnackbarMessage({'message': message, 'severity': type}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));
  }

  return (
    <Grid  item container xs={12} sm={12} padding={2} mt={2} md={12} lg={12} xl={12} justifyContent="center" alignItems={'center'} spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
        <Card
          className='glass-box'
          sx={{
            py: 5,
            px: 5,
            boxShadow: 0,
            textAlign: 'center',
            color: '#000',
            bgcolor: '#eee',       
          }}
          
        >
          {/* <Grid container justifyContent="start" alignItems="center" onClick={() => { 
            if(selectedAccountType === ACCOUNT_TYPE.PERSONAL){ 
              dispatch(setSelectedAccountType({type: null})); 
            }
            if(selectedAccountType === ACCOUNT_TYPE.CORPORATE){
              dispatch(setSelectedCorporate({corporate: null}));    
            }
            dispatch(clearOTPState( ));
          }}
              style={{cursor:'pointer'}}>              
              <Iconify icon="bytesize:arrow-left" />
              <Box padding={0.5}/>
              <span>Go Back</span>
          </Grid> */}
          <Box margin={4}/>
          {/* <Grid container justifyContent="center" alignItems="center"  
               >              
              {
                (selectedAccountType === ACCOUNT_TYPE.PERSONAL) ? <h2>Welcome Back!</h2> : null
              }
              {
                (selectedAccountType === ACCOUNT_TYPE.CORPORATE) ? <h2>{selectedCorporate ? selectedCorporate.display_name : ""}</h2> : null
              }
          </Grid> */}
          <h2>Welcome Back!</h2>
          <Box margin={4}/>
          {/* <Grid container justifyContent="start" alignItems="center"  
              >              
              <span>Please enter your registered mobile number</span>
          </Grid> */}
          <Box margin={1}/>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="mobile_number"
                  type="number"
                  label="Mobile Number"
                  onKeyUp={e => {
                    setCookie("mobile", e.target.value, { path: '/' })
                  }}
                  value={cookies.mobile}
                  {...getFieldProps('mobile')}
                  error={Boolean(touched.mobile && errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                />
              { 
                showOTPContainer  
                ? <TextField
                  fullWidth
                  autoComplete="current-otp"
                  // type="number"
                  // type={showPassword ? 'number' : 'password'}
                  label="OTP"
                  onKeyUp={e => {
                    setOTPValue(e.target.value);
                  }}
                  {...getFieldProps('otp')}
                  InputProps={{
                    // endAdornment: (
                    //   <InputAdornment position="end">
                    //     <IconButton onClick={handleShowPassword} edge="end">
                    //       <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    //     </IconButton>
                    //   </InputAdornment>
                    // ),
                  }}
                  error={Boolean(touched.otp && errors.otp)}
                  helperText={touched.otp && errors.otp}
                />
                : <Container />  }
              </Stack>

              { showOTPContainer  ? 
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <FormControlLabel
                  control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                  label="Remember me"
                />

                <Link component={RouterLink} variant="subtitle2" to="#" underline="hover" onClick={() => handleSendOTPClick()}>
                  Resend OTP?
                </Link>
              </Stack>
              : <Container />  }

              { !showOTPContainer  && errors.mobile  ? 
                <LoadingButton fullWidth size="large" onClick={() => displayMessage("error", OTP_ERROR.INVALID_MOBILE)} variant="contained" loading={isSubmitting}>
                  Send OTP
                </LoadingButton>
                : null  
              }

              { !showOTPContainer  && !errors.mobile  ? 
                <LoadingButton fullWidth size="large" onClick={() => handleSendOTPClick()} variant="contained" loading={isSubmitting}>
                  Send OTP
                </LoadingButton>
                : null  
              }

              { showOTPContainer && errors.otp  ? 
              <LoadingButton fullWidth size="large" onClick={() => displayMessage("error", OTP_ERROR.INVALID_OTP)} variant="contained" loading={isSubmitting}>
                Verify OTP
              </LoadingButton> 
                : <Container />  }   

              { showOTPContainer && !errors.otp ? 
              <LoadingButton fullWidth size="large" onClick={() => handleVerifyOTPClick()} variant="contained" loading={isSubmitting}>
                Verify OTP
              </LoadingButton> 
                : <Container />  }   

            </Form>
          </FormikProvider>
      
        </Card>
      </Grid>
    </Grid>
  );
}
