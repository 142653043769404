import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import BottomNavbar from '../components/BottomNavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden', 
  flexDirection:'column' ,
  // backgroundColor: 'orange'
  // background: 'hsla(298, 68%, 90%, 1)',
  // background: 'linear-gradient(90deg, #ebf4f5 0%, #b5c6e0 100%)',
  // background: 'linear-gradient(180deg, #e3ffe7 0%, #d9e7ff 100%)',
  // background: 'hsla(234, 80%, 88%, 1)',

  // background: 'linear-gradient(180deg, hsla(234, 80%, 88%, 1) 0%, hsla(340, 68%, 88%, 1) 50%, hsla(342, 72%, 85%, 1) 100%)',

  // background: '-moz-linear-gradient(90deg, hsla(234, 80%, 88%, 1) 0%, hsla(340, 68%, 88%, 1) 50%, hsla(342, 72%, 85%, 1) 100%)',

  // background: '-webkit-linear-gradient(90deg, hsla(234, 80%, 88%, 1) 0%, hsla(340, 68%, 88%, 1) 50%, hsla(342, 72%, 85%, 1) 100%)',

  // filter: 'progid: DXImageTransform.Microsoft.gradient( startColorstr="#CACFF9", endColorstr="#F5CBD9", GradientType=1 )',
  // background: 'linear-gradient(180deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%)',
  // background: '-webkit-linear-gradient(90deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%)',
  // filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#F6D5F7", endColorstr="#FBE9D7", GradientType=1 )'
});

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  // padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    // padding: theme.spacing(5, 5, 0),
  },
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100vh',
  width:'100vw',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2)
  }
})); 

const FooterStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // overflow: 'auto',
  width:'100%',
  flexShrink: 0,
  textAlign: 'center',
  backgroundColor: 'red',
  color: 'white',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // }
})); 

const BottomNavStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // overflow: 'auto',
  width:'100%',
  position:'fixed',
  bottom:0,
  left:0,
  right:0,
  flexShrink: 0,
  textAlign: 'center',
  height:'70px',
  backgroundColor: '#e86826',
  color: 'white',
  display:'flex',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    display: 'none!important',
    //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  }
})); 

// ----------------------------------------------------------------------
export default function NoLogoLayout() {
  return (
    <RootStyle>
      <MainStyle> 
        <Outlet />
      </MainStyle>
      {/* <Footer/>  */}
      <BottomNavStyle>
        <BottomNavbar />
      </BottomNavStyle>
    </RootStyle>
  );
}
