 // @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react'; 
import { useCookies } from 'react-cookie';  
import GooglePayButton from '@google-pay/button-react';
import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material'; 
import { useDispatch, useSelector } from 'react-redux';
import { getCartItems, getGrandTotal, getInvalidCartMessage, getPaymentModes, getPriceBreakdown, isCartValid, setDeliveryInstruction, setDeliveryPreference } from '../../store/cart/reducer';
import CartItemCard from './CartIitemCard';
import { fetchCart } from '../../data/network/cart/api';
import { generateCorporateOrderId } from '../../data/network/order/api';
import {PAYMENT_GATEWAY, PAYMENT_MODE, PAYMENT_STATUS, RAZORPAY_KEY, USER_PAYMENT_TYPE} from '../../constants/app_constants';
import { setPaymentStatus, setPaymentMode, resetPaymentState, getPaymentGateway, setPaymentGateway, getSelectedPaymentMode, getPaymentMode } from '../../store/payment/reducer';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/layout/reducer';
import { CART_ERROR } from '../../constants/message_constants';
import Iconify from '../../components/Iconify';
import { getUserCorporatePaymentType } from '../../store/user/reducer'; 
import { getSelectedCorporate, getSelectedSubCorporate, getSelectedTableId, getSubCorporateList, setSelectedSubCorporate } from '../../store/otp/reducer';
 // components

import '../../assets/css/Cart.css';
import CustomizeOption from './CustomizeOption';
import { resetCustomizeItem } from '../../store/menu/reducer';
import { SelectCorporate } from '../auth/login';
import { fetchCorporateList } from '../../data/network/corporate/api';

// ----------------------------------------------------------------------
  
export default function OrderSummary( ) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const paymentRef = useRef();

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const cartItems = useSelector(state => getCartItems(state));
    const priceBreakdown = useSelector(state => getPriceBreakdown(state));  
    const grandTotal = useSelector(state => getGrandTotal(state));  
    const isValidCart = useSelector(state => isCartValid(state));
    const invalidCartMessage = useSelector(state => getInvalidCartMessage(state));  
    const userPaymentType = useSelector(state => getUserCorporatePaymentType(state));
    const selectedPaymentGateway = useSelector(state => getPaymentGateway(state));

    const selectedCorporate = useSelector(state => getSelectedCorporate(state));
    const subCorporateList = useSelector(state => getSubCorporateList(state));
    const subCorporate = useSelector(state => getSelectedSubCorporate(state));
    const tableId = useSelector(state => getSelectedTableId(state));
    const paymentModes = useSelector(state => getPaymentModes(state));
    const selectedPaymentMode = useSelector(state => getPaymentMode(state));

    const [selectedDeliveryPreference, setSelectedDeliveryPreference] = useState('desk');
    const [deliveryInstruction, setEnteredDeliveryInstruction] = useState('');

    const [paymentModeSelect, setPaymentModeSelect] = useState(selectedPaymentMode);

    const processOrder = () =>{
        dispatch(resetPaymentState());
        fetchCart(dispatch, cookies); 
        if(isValidCart){
            try{
                dispatch(setPaymentStatus({'status' : PAYMENT_STATUS.PENDING}));
                dispatch(setPaymentMode({'mode' : paymentModeSelect})); 
                dispatch(setDeliveryPreference({'preference' : selectedDeliveryPreference})); 
                console.log(deliveryInstruction);
                dispatch(setDeliveryInstruction({'instruction' : deliveryInstruction})); 
                navigate('/payment/');
            }catch(e){
                console.log(e);
            }
        }else{
            // display Message
            displayMessage("error", CART_ERROR.NOT_SERVICABLE);
        }
    };

    const processOrderWithoutPayment = () =>{
        dispatch(resetPaymentState());
        fetchCart(dispatch, cookies);

        if(isValidCart){ 
            dispatch(setPaymentStatus({'status' : PAYMENT_STATUS.PENDING}));
            dispatch(setPaymentMode({'mode' : PAYMENT_MODE.ONLINE}));
            navigate('/payment/');
            
        }else{ 
            // display Message
            displayMessage("error", CART_ERROR.NOT_SERVICABLE);
        }
    };

    const displayMessage = (type, message) => { 
        dispatch(setSnackbarMessage( {'message' : message, 'severity' : type})); 
        dispatch(setSnackbarVisibilityStatus( {'status' : true})); 
    }

    const handlePaymentMethodChange = (data) => {
        dispatch(setPaymentGateway({'gateway' : data.target.value}));
    }


    useEffect(() => {
        fetchCorporateList(dispatch);
        fetchCart(dispatch, cookies);

        // scroll to class = price_breakup_single
        paymentRef.current?.scrollIntoView({'behavior' : 'smooth'});
    },[]);

    return (
        <Grid 
            className='order-summary-container  glass-box'
            style={{
                padding:2,
                minHeight:'40vh', 
            }}>

            <Grid container >
                {
                    cartItems && cartItems.map((e) => 
                        <div key={e.cart_item_id}  style={{width:'100%'}}> 
                            <CartItemCard key={e.cart_item_id} item={e} />
                            <Divider spacing={1} sx={{ width:'100%',borderStyle: 'dashed' }}/>
                        </div>
                    )
                }
            </Grid>
            <Box margin={4} />
            {cartItems && cartItems.length > 0
            ? <Grid className="w-100 price_breakup_container pad-x-8">
                {
                    priceBreakdown.map((e) => 
                        (e.value !== 0) && <Grid key={e.name} className="price_breakup_single w-100">
                            <span>
                            {e.display_name}
                            </span>

                            <span className={e.name === 'discount' ? 'price-breakdown-discount' : ''}>
                            {e.name === 'discount' ? '-' : ''} ₹ {e.value}
                            </span>
                        </Grid>
                    )
                }

                <Box margin={2} />
                <Divider spacing={1} sx={{ borderStyle: 'dashed' }}/>
                
                <Box margin={2} />
                <Grid className="price_breakup_single w-100 grand_total">
                        <h3 className="grand_total_label">
                        Grand Total
                        </h3>

                        <h3 className="grand_total_value">
                        ₹ {grandTotal}
                        </h3>
                </Grid>

                <Box margin={2} />
                {/* <Grid className="price_breakup_single w-100 grand_total">
                    <h3 className="grand_total_label">
                        Payment Method 
                    </h3>


                    <select onChange={handlePaymentMethodChange} value={selectedPaymentMethod}>
                        {
                            Object.keys(PAYMENT_GATEWAY).map((key) => {
                                return (PAYMENT_GATEWAY[key].enabled) 
                                ? <option value={PAYMENT_GATEWAY[key].name}>{PAYMENT_GATEWAY[key].label}</option> 
                                : <></>
                            })
                        }


                    </select>
                </Grid>

                <Box margin={2} /> */}
                <Divider spacing={1} sx={{ borderStyle: 'dashed' }}/>

                <Box margin={2} />

                <Grid className="price_breakup_single w-100">
                    <span>
                        Select Delivery  
                    </span>

                    {
                        (subCorporateList && subCorporateList.length>0)
                        ? <Card sx={{borderRadius:1}} >
                            <select 
                                // defaultChecked={subCorporate && subCorporate.id}
                                className={'delivery-preference-select'}
                                onChange={(item) => {     
                                    try{
                                        dispatch(setSelectedSubCorporate({subCorporate: subCorporateList.find(x => x.id.toString() === item.target.value.toString())}))
                                    }catch(e){
                                        console.log(e);
                                    }
                            }}>
                                {
                                    subCorporateList.map(e => {
                                        return <option                                
                                            value={e.id}>
                                            {e.display_name}
                                        </option>
                                    })
                                }
                            </select>
                        </Card>   
                        : <h4>{subCorporate && subCorporate.display_name}</h4>
                    
                    }
                </Grid>

                <Box margin={1} />
                <Grid className="price_breakup_single w-100">
                    <span>
                        Collect at
                    </span>
                    <Card sx={{borderRadius:1}} >
                        <select 
                            defaultValue={selectedDeliveryPreference}
                            className={'delivery-preference-select'}
                            onChange={(item) => {     
                            setSelectedDeliveryPreference(item.target.value);
                        }}>
                            <option value="desk">Office Desk</option>
                            <option value="table">Cafe Table</option>
                        </select>   
                    </Card>   
                </Grid>
                
                <Box margin={1} />

                {
                    selectedDeliveryPreference === 'desk' &&
                    <Grid className="price_breakup_single w-100">
                        <span>
                            Desk Instructions
                        </span>
                        <Card sx={{borderRadius:1}} >
                            <input type="text" value={tableId} id="table_id" name="table_id" className="delivery-instruction-input" 
                                onChange={(text) => {
                                    setEnteredDeliveryInstruction(text.target.value);
                                }}
                            />
                        </Card>
                    </Grid>
                }
                
                <Box margin={1} />

                {/* {(selectedDeliveryPreference === 'table' || (tableId && tableId !== null)) && <Grid className="price_breakup_single w-100">
                    <span>
                        Table No
                    </span>
                    <Card sx={{borderRadius:1}} >
                        <input type="text" value={tableId} id="table_id" name="table_id" className="delivery-instruction-input"
                            onChange={(text) => {
                                setEnteredDeliveryInstruction(text.target.value);
                            }}
                        />
                    </Card>
                </Grid>} */}

                {/* <Box margin={1} /> */}
                <Divider spacing={1} sx={{ borderStyle: 'dashed' }}/>
                <Box margin={1} />

                {paymentModes && paymentModes.length>0 && <Grid className="price_breakup_single w-100">
                    <span>
                        Choose Payment Method
                    </span> 
                    <Card sx={{borderRadius:1}} ref={paymentRef} >
                        <select 
                            defaultValue={selectedPaymentMode.toUpperCase()}
                            className={'delivery-preference-select'}
                            onChange={(item) => {     
                                setPaymentModeSelect(item.target.value); 
                        }}>
                            {
                                paymentModes.map(mode => {
                                    return <option value={mode.name}>{mode.display_name}</option>
                                })
                            }
                        </select>
                    </Card>
                </Grid>}

                <Box margin={10} />


                {/* { 
                    ( isValidCart)
                    ? <Button variant='contained' style={{width:'80%', padding:10, margin:'10%'}} onClick={() => {
                    processOrder(); 
                    }}>
                        {userPaymentType === USER_PAYMENT_TYPE.CORPORATE_PAID ? 'Place Order' : 'Continue to Payment'}
                    </Button>
                    : <Button variant='contained' style={{width:'80%', padding:10, margin:'10%', background: '#9e9e9e'}} onClick={() => {
                    displayMessage("error", CART_ERROR.NOT_SERVICABLE ); 
                    }}>
                        {userPaymentType === USER_PAYMENT_TYPE.CORPORATE_PAID ? 'Place Order' : 'Continue to Payment'}
                    </Button>
                } */}

                {/* {
                    (isValidCart)
                    ? <GooglePayButton
                        environment="TEST"
                        paymentRequest={{
                        apiVersion: 2,
                        apiVersionMinor: 0,
                        allowedPaymentMethods: [
                            {
                            type: 'CARD',
                            parameters: {
                                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                allowedCardNetworks: ['MASTERCARD', 'VISA'],
                            },
                            tokenizationSpecification: {
                                type: 'PAYMENT_GATEWAY',
                                parameters: {
                                gateway: 'example',
                                gatewayMerchantId: 'exampleGatewayMerchantId',
                                },
                            },
                            },
                        ],
                        merchantInfo: {
                            merchantId: '12345678901234567890',
                            merchantName: 'Demo Merchant',
                        },
                        transactionInfo: {
                            totalPriceStatus: 'FINAL',
                            totalPriceLabel: 'Total',
                            totalPrice: '100.00',
                            currencyCode: 'USD',
                            countryCode: 'US',
                        },
                        }}
                        onLoadPaymentData={paymentRequest => {
                        console.log('load payment data', paymentRequest);
                        }}
                    />
                    :  <></>
                } */}

                {/* {
                    (isValidCart)
                    ? <PaytmButton />
                    : <></>
                } */}

                {cartItems.length > 0 && <Grid className='fab-payment-container'>
                    <Grid 
                        container 
                        flexDirection='row' 
                        justifyContent='space-between' 
                        style={{color:'#fff',fontSize:'16px'}}
                        onClick={() => {
                            if(isValidCart){
                                processOrder();
                            }else{
                                displayMessage("error", CART_ERROR.NOT_SERVICABLE ); 
                            }
//                            navigate('/payment');
                            // ref.current?.scrollIntoView({behavior: 'smooth', top:180});
                        }}
                        >
                        <p>
                        {cartItems.length} items | ₹{grandTotal}
                        </p>
                        <span>{userPaymentType === USER_PAYMENT_TYPE.CORPORATE_PAID ? 'Place Order' : 'Pay now'}</span>
                    </Grid>
                    </Grid>
                }
            </Grid>
            : <Grid container direction="column" justifyContent="center" alignItems="center">
                <Iconify icon="eva:shopping-bag-outline" sx={{ width: 64, height: 64, ml: 1 }}/>
                <Box margin={1} />
                <Typography>Your cart is empty</Typography>
            </Grid>
        }
        </Grid>
   );
 }
 