import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
//
import { Box, Stack, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { getSession } from '../data/network/session/api';
import { getLoginStatus, getUser, logoutUser, setActiveCorporateClient } from '../store/user/reducer';
import { fetchUserDetails } from '../data/network/user/api';
import Footer from './Footer';
import NavSection from './NavSection';
import { getSideNavigationMenuConfig } from '../utils/nav_helper';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
//   minHeight: '240px',
  overflow: 'hidden', 
//   border: '1px solid #e0e0e0',
  borderRadius: '10px',
// 
//   WebkitFilter: 'blur(10px)',
});

const MainContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
//   minHeight: '240px',
  display:'none',
  background: 'transparent',
  // backdropFilter: 'blur(10px)',
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(28),
    // paddingRight: theme.spacing(2),
    display:'flex',
}
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
//   minHeight: '240px',
  display:'none',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  }
}));

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
// ----------------------------------------------------------------------

export default function Navbar({showHeader=true, showFooter=true}) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const navigate = useNavigate();



  return (
    <RootStyle>
      
      <MainStyle>
        <MainContent>
            <NavSection navConfig={getSideNavigationMenuConfig( )} />
        </MainContent>
      </MainStyle> 
    </RootStyle>
  );
}
