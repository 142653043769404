import { faker } from '@faker-js/faker';
// @mui
import { useTheme,styled, darken, alpha, lighten  } from '@mui/material/styles';
import { Grid, Container, Typography, FormControl, InputLabel, Select, Box, Chip, OutlinedInput, MenuItem, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  DayView,
  WeekView,
  ViewSwitcher,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  TodayButton,
  EditRecurrenceMenu,
  IntegratedEditing,
  Resources,
  DragDropProvider,
} from '@devexpress/dx-react-scheduler-material-ui';// components
import { Restaurant } from '@mui/icons-material';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import  classNames from 'clsx';
import Page from '../components/Page'; 
import { calendarMealType } from '../constants/app_constants';
import { fetchUserCorporateMeal } from '../data/network/user/api';
import { formatDate } from '../utils/date';
import { getUserCorporateMeal } from '../store/user/reducer';
// sections
 
import '../assets/css/MyCalendar.css';
// ----------------------------------------------------------------------

export default function MyCalendar() {
  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const appointments = []; 

  const [data, setData] = useState(appointments);

  const [addedAppointment, setAddedAppointment] = useState({});
  const [appointmentChanges, setAppointmentChanges] = useState({});
  const [editingAppointment, setEditingAppointment] = useState(undefined);
  const [isAppointmentBeingCreated, setIsAppointmentBeingCreated] = useState(false);
  const corporateMealList = useSelector(state => getUserCorporateMeal(state));
 
  const [range, setRange] = useState({
    'start_date' : null,
    'end_date' : null,
  });

  const [currentView, setCurrentView] = useState("Week");


  
  const PREFIX = 'schedule-meal'; 
  const classes = {
      cell: `${PREFIX}-cell`,
      content: `${PREFIX}-content`,
      text: `${PREFIX}-text`,
      sun: `${PREFIX}-sun`,
      cloud: `${PREFIX}-cloud`,
      rain: `${PREFIX}-rain`,
      sunBack: `${PREFIX}-sunBack`,
      cloudBack: `${PREFIX}-cloudBack`,
      rainBack: `${PREFIX}-rainBack`,
      opacity: `${PREFIX}-opacity`,
      appointment: `${PREFIX}-appointment`,
      apptContent: `${PREFIX}-apptContent`,
      flexibleSpace: `${PREFIX}-flexibleSpace`,
      flexContainer: `${PREFIX}-flexContainer`,
      tooltipContent: `${PREFIX}-tooltipContent`,
      tooltipText: `${PREFIX}-tooltipText`,
      title: `${PREFIX}-title`,
      icon: `${PREFIX}-icon`,
      circle: `${PREFIX}-circle`,
      textCenter: `${PREFIX}-textCenter`,
      dateAndTitle: `${PREFIX}-dateAndTitle`,
      titleContainer: `${PREFIX}-titleContainer`,
      container: `${PREFIX}-container`,
  };

  const getBorder = theme => (`1px solid ${
      theme.palette.mode === 'light'
        ? lighten(alpha(theme.palette.divider, 1), 0.88)
        : darken(alpha(theme.palette.divider, 1), 0.68)
  }`); 

  const CellBase =  memo(({
      startDate,
      formatDate,
      otherMonth,
    }) => {
      const iconId = Math.abs(Math.floor(Math.sin(startDate.getDate()) * 10) % 3);
      const isFirstMonthDay = startDate.getDate() === 1;
      const formatOptions = isFirstMonthDay
        ? { day: 'numeric', month: 'long' }
        : { day: 'numeric' };
      return (
        <StyledTableCell
          tabIndex={0}
          className={classNames({
            [classes.cell]: true,
            [classes.cloudBack] : true,
          //   [classes.rainBack]: iconId === 0,
          //   [classes.sunBack]: iconId === 1,
          //   [classes.cloudBack]: iconId === 2,
            [classes.opacity]: otherMonth,
          })}
        >
          <StyledDivContent className={classes.content}>
            {/* <WeatherIcon classes={classes} id={iconId} /> */}
          </StyledDivContent>
          <StyledDivText className={classes.text}>
            {formatDate(startDate, formatOptions)}
          </StyledDivText>
        </StyledTableCell>
      );
  });

  

  const resources = [{
      fieldName: 'mealType',
      title: 'Meal Type',
      instances: calendarMealType,
    }
  ];
    
  const TimeTableCell = (CellBase);
    
  const DayScaleCell = props => (
      <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${classes.cell}`]: {
      color: '#78909C!important',
      position: 'relative',
      userSelect: 'none',
      verticalAlign: 'top',
      padding: 0,
      height: 200,
      borderLeft: getBorder(theme),
      '&:first-of-type': {
          borderLeft: 'none',
      },
      '&:last-child': {
          paddingRight: 0,
      },
      'tr:last-child &': {
          borderBottom: 'none',
      },
      '&:hover': {
          backgroundColor: 'white',
      },
      '&:focus': {
          backgroundColor: alpha(theme.palette.primary.main, 0.15),
          outline: 0,
      },
      },
      [`&.${classes.sunBack}`]: {
      backgroundColor: '#FFFDE7',
      },
      [`&.${classes.cloudBack}`]: {
      backgroundColor: '#ECEFF1',
      },
      [`&.${classes.rainBack}`]: {
      backgroundColor: '#E1F5FE',
      },
      [`&.${classes.opacity}`]: {
      opacity: '0.5',
      },
  }));
  const StyledDivText = styled('div')(() => ({
      [`&.${classes.text}`]: {
      padding: '0.5em',
      textAlign: 'center',
      },
  }));

  const StyledDivContent = styled('div')(() => ({
      [`&.${classes.content}`]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      position: 'absolute',
      alignItems: 'center',
      },
  }));

  const StyledAppointmentsAppointment = styled(Appointments.Appointment)(() => ({
      [`&.${classes.appointment}`]: {
      borderRadius: '10px',
      '&:hover': {
          opacity: 0.6,
      },
      },
  }));

  const StyledToolbarFlexibleSpace = styled(Toolbar.FlexibleSpace)(() => ({
      [`&.${classes.flexibleSpace}`]: {
      flex: 'none',
      },
      [`& .${classes.flexContainer}`]: {
      display: 'flex',
      alignItems: 'center',
      },
  }));
  const StyledAppointmentsAppointmentContent = styled(Appointments.AppointmentContent)(() => ({
      [`&.${classes.apptContent}`]: {
      '&>div>div': {
          whiteSpace: 'normal !important',
          lineHeight: 1.2,
      },
      },
  }));
  
  const Appointment = (({ ...restProps }) => (
      <StyledAppointmentsAppointment
        {...restProps}
        className={classes.appointment}
      />
  ));
    
  const AppointmentContent = (({ ...restProps }) => (
      <StyledAppointmentsAppointmentContent {...restProps} className={classes.apptContent} />
  ));
    
  const FlexibleSpace = (({ ...restProps }) => (
      <StyledToolbarFlexibleSpace {...restProps} className={classes.flexibleSpace}>
        <div className={classes.flexContainer}>
          <Restaurant fontSize="large" htmlColor="#e86826" />
          <Typography variant="h6" style={{ marginLeft: '10px',marginRight: '10px' }}>Meal Calendar</Typography>
        </div>
      </StyledToolbarFlexibleSpace>
  ));

  const changeAddedAppointment= ({addedAppointment }) => {
    // setAddedAppointment(addedAppointment);
    // setIsAppointmentBeingCreated(true);
  };

  const changeAppointmentChanges= ({appointmentChanges }) => { 
//    setAppointmentChanges(appointmentChanges);
  };

  const changeEditingAppointment= ({editingAppointment }) => { 
    // setEditingAppointment(editingAppointment);
  };

  const onCommitChanges = useCallback(({ added, changed, deleted }) => {
    // if (added !== undefined && added !== {} && userId !=null) {
    //   // console.log(added);
    //   // addMealPlanSchedule(dispatch, cookies, {
    //   //   "user_id": userId,
    //   //   "meal_type": selectedMealType,
    //   //   "preference": (activeClient == null || activeClient.meal_preference == null) ? "veg" : activeClient.meal_preference,
    //   //   "dishes" : selectedDish.map(String),
    //   //   "date": formatDate(added.startDate)
    //   // });

    //   // const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
    //   // setData([...data, { id: startingAddedId, ...added }]);
    // }
    // if (changed) {
    //   setData(data.map(appointment => (
    //     changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment)));
    // }
    // if (deleted !== undefined) {
    //   setData(data.filter(appointment => appointment.id !== deleted));
    // }
    // setIsAppointmentBeingCreated(false);
  // }, [setData, setIsAppointmentBeingCreated, data, selectedMealType, selectedDish, activeClient]);
}, [ ]);
  const BoolEditor = (props) => null;

  const LabelComponent = (props) => null;

  const InputComponent = (props) => null;
 
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event; 

  //   setSelectedDish(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );

  // };

  // const handleMealTypeChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event; 

  //   setSelectedMealType(value);
  // }

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
  }; 


 
  return (
      // <AppointmentForm.BasicLayout
      //   appointmentData={appointmentData}
      //   onFieldChange={onFieldChange}
      //   {...restProps}
      // >
      <Container sx={{marginTop: 4}}>
        {/* <FormControl sx={{ width: '100%', marginTop:2 }}>
          <InputLabel id="select-meal-type-label">Meal Type</InputLabel>
          <Select
            required
            labelId="select-meal-type-label"
            id="select-meal-type"
            value={selectedMealType == null ? "" : selectedMealType}
            onChange={handleMealTypeChange}
            input={<OutlinedInput id="select-meal-type" label="Meal Type" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}> 
                { 
                  calendarMealType.map((value) =>  
                    (value.id === selected)
                    ? <Chip key={value.id } label={value.text } /> 
                    : null
                  ) 
                }
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {calendarMealType.map((e) => (
              <MenuItem
                key={e.id}
                value={e.id} 
              >
                {e.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '100%', marginTop:2 }}>
          <InputLabel id="select-dishes-label">Dishes</InputLabel>
          <Select
            labelId="select-dishes-label"
            id="select-dishes"
            multiple
            value={selectedDish}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-dish" label="Dish" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}> 
                { 
                  selected.map((value) =>   
                    mealOptions.map((element, val) => ( 
                      (element.product_id === value) 
                        ? <Chip key={element.product_id} label={element.display_name} /> 
                        : null
                    ))
                  ) 
                }
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {mealOptions.map((e) => (
              <MenuItem
                key={e.product_id}
                value={e.product_id} 
              >
                {e.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Container>
      // </AppointmentForm.BasicLayout>
    );
  };

  useEffect(() =>{ 
    const rangeDetails = getRange(new Date(), currentView);
    setRange({
      'start_date' : rangeDetails.startDate,
      'end_date' : rangeDetails.endDate
    });  
  },[currentView ]) 

  useEffect(() =>{ 
    if(range.start_date != null && range.end_date != null){ 
      fetchUserCorporateMeal(dispatch, cookies, {
        "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
        "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
      });
    }
  },[range]) 

  useEffect(() =>{ 
    let c = 0;
    if(corporateMealList && corporateMealList.length > 0){
      corporateMealList.forEach(element => {
        console.log(element);
        let hrs = 12; 
        switch(element.service_type.id){
          case 1:
            hrs = 8;
            break;

          case 2:
            hrs = 12;
            break;

          case 3:
            hrs = 16;
            break;

          case 4: 
            hrs = 16;
            break;

          default:
            hrs = 16;
            break;
        }
        const startDateLabel = new Date(element.scheduled_date).setHours(hrs, 0, 0, 0);
        const endDateLabel = new Date(element.scheduled_date).setHours(hrs+4, 0, 0, 0);
        appointments.push({
          id: c,
          title: element.menu_name,
          startDate: startDateLabel,
          endDate: endDateLabel,
          mealType: element.service_type.id,
        });
        c += 1;
      });

      setData(appointments);
    }
  },[corporateMealList ]) 

  const getRange = (date, view) => {
    if (view === "Day") {
      return { startDate: date, endDate: date };
    }
    if (view === "Week") {
      const firstDay = date.getDate() - date.getDay();
      const lastDay = firstDay + 6;
      return {
        startDate: new Date(date.setDate(firstDay)),
        endDate: new Date(date.setDate(lastDay))
      };
    }
  };

  const currentDateChange = (currentDate) => {
    const rangeDetails = getRange(currentDate, currentView);
    setRange({
      'start_date' : rangeDetails.startDate, 
      'end_date' : rangeDetails.endDate
    }); 
  };

  return (
    <Page title="My Calendar">
      <Container maxWidth="xl">
        <Grid container justifyContent="center" alignItems="center" direction="row">
            <Scheduler data={data} >
                <EditingState 
                  // onCommitChanges={ onCommitChanges } 
                  // addedAppointment={addedAppointment}
                  // onAddedAppointmentChange={ changeAddedAppointment }
                  // appointmentChanges={appointmentChanges}
                  // onAppointmentChangesChange={ changeAppointmentChanges }
                  // editingAppointment={editingAppointment}
                  // onEditingAppointmentChange={ changeEditingAppointment }
                />
                
                <ViewState 
                  defaultCurrentDate={new Date()} 
                  // currentView={currentView}
                  onCurrentDateChange={currentDateChange}
                />

                <WeekView
                  startDayHour={8}
                  endDayHour={20}
                  cellDuration={240}
                />
                {/* <DayView  startDayHour={8}
                  endDayHour={20}
                  cellDuration={240}/> */}
                <Appointments appointmentComponent={Appointment} appointmentContentComponent={AppointmentContent} />
                <Resources data={resources} />
                <Toolbar flexibleSpaceComponent={FlexibleSpace} />
                {/* <ViewSwitcher /> */}
                <DateNavigator />
                <TodayButton />
                {/* <EditRecurrenceMenu /> */}
                <AppointmentTooltip
                    showCloseButton
                    // showDeleteButton
                    // showOpenButton 
                />
                {/* <AppointmentForm 
                    basicLayoutComponent={BasicLayout}
                    booleanEditorComponent={BoolEditor}
                    labelComponent={LabelComponent}
                    textEditorComponent={InputComponent}  /> */}
                
            </Scheduler>
        </Grid>
  
      </Container>
    </Page>
  );
}
