import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { useCookies } from 'react-cookie';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserName, logoutUser } from '../../store/user/reducer';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
// import navConfig from './NavConfig';

import { getSideNavigationMenuConfig } from '../../utils/nav_helper';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 160;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const userName = useSelector(state => getUserName(state));

  const userDetails = useSelector(state => getUser(state));
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = () => {
    removeCookie('mobile', { path: '/' });
    removeCookie('session', { path: '/' });
    removeCookie('access_token', { path: '/' });
    dispatch(logoutUser({data: {}})); 
  }

  const renderContent = (
    <Scrollbar
      className='glass-box' 
      sx={{
        height: 1,
        borderRadius:'0!important',
        '& .simplebar-content': {  display: 'flex', flexDirection: 'column' },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <a href='/'> 
          <img src='/static/logo.png' alt="Bytes" width={100}  /> 
        </a>
      </Box> */}

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={
                (userDetails !== null && userDetails.img !== undefined)
                ? userDetails.img
                : account.photoURL
              } alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {
                  (userDetails !== null && userDetails.user_name !== undefined)
                  ? userDetails.user_name
                  : ""
                }
              </Typography>

              {
                userDetails.roles !== undefined &&
                userDetails.roles.forEach(element => {
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {
                      (userDetails.roles !== undefined && userDetails.roles[0].display_name !== undefined)
                      ? userDetails.roles[0].display_name
                      : ""
                    }
                  </Typography>
                }) 
              } 
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <NavSection navConfig={getSideNavigationMenuConfig( )} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2 , pb: 3, mt: 0 }}>
        <Stack alignItems="start" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
 
          <Box sx={{ textAlign: 'start', width:'100%'  }}>
            <ListItemStyle
              // component={RouterLink}
              // to={'/logout'}
              onClick={handleLogout} 
              sx={{
                // ...(isActiveRoot && activeRootStyle),
              }}
            >
              {/* <ListItemIconStyle> <Iconify icon="eva:pie-chart-2-fill" width={22} height={22} /></ListItemIconStyle> */}
              <ListItemText disableTypography primary="Logout" /> 
            </ListItemStyle>
          </Box>

        </Stack>
      </Box>

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
          </Box>

        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {/* {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )} */}

      {isDesktop && (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
