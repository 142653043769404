import { Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../components/Iconify";

import { getCartItems, getItemQty } from "../../store/cart/reducer";
import { decreaseCustomizeItemQty, getCustomizationItem, getCustomizationItemAddonQty, getCustomizationItemPrice, getCustomizationItemQty, getEditingCartItemId, getNonCustomizationItemQty, increaseCustomizeItemQty, resetCustomizeItem, setCustomizeItem, updateCustomizeItem } from "../../store/menu/reducer";
import { SNACKBAR_SEVERITY } from "../../constants/app_constants";
import { setSnackbarMessage, setSnackbarVisibilityStatus } from "../../store/layout/reducer";
import { updateCartApi } from "../../data/network/cart/api";

export default function CustomizeOption({item, isOpen, handleClose}) {
    
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const addonQty = useSelector(state => getCustomizationItemAddonQty(state));
    const customizedQty = useSelector(state => getCustomizationItemQty(state));
    const customizedPrice = useSelector(state => getCustomizationItemPrice(state));
    const customizedItem = useSelector(state => getCustomizationItem(state));
    const editingCartItemId = useSelector(state => getEditingCartItemId(state));

    const itemQtyInCart = useSelector(state => getItemQty(state, item));    
    const cartItems = useSelector(state => getCartItems(state, item));
    const nonCustomizedItemQty = useSelector(state => getNonCustomizationItemQty(state));

    const CustomizeOptionHeader = ({item}) => {
        return (
            <Grid>
                <Box margin={1} />
                <div style={{
                    position: 'fixed',
                    right: 20,
                    top: 20,
                    borderRadius: 50,
                    backgroundColor: 'rgba(255, 255, 255)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding:'4px',
                    boxShadow:'1px 4px 8px 1px #9e9e9e59'
                }}>
                    <Iconify sx={{fontSize:24}} icon="eva:close-outline" onClick={() => {
                        handleClose();
                    }} />
                </div>
                <Grid item container flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Typography variant="body1">{item.display_name}</Typography>
                    <Typography variant="h5">Customise as per your taste</Typography>
                </Grid>
                <Box margin={1} />
            </Grid>
        );
    }
    
    const CustomizeOptionFooter = () => {
        return (
            <Grid container justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'}>
                <Grid container spacing={0}
                    direction="row"
                    justifyContent="center"
                    flexWrap={"nowrap"}
                    alignItems="center"
                    className="change_qty"
                    maxWidth='40%'
                    border={'1px solid #e0e0e0'}
                    borderRadius={1}
                    boxShadow={'1px 4px 8px 1px #9e9e9e59'}
                    >
                    <Grid item sx={{padding:0}}>
                        <Button className="change-qty-customization" onClick={() => {
                            if (customizedQty > 1) {
                                dispatch(decreaseCustomizeItemQty({}));
                            }else if(customizedQty === 1) {
                                if (editingCartItemId !== undefined && editingCartItemId !== null) {
                                    dispatch(decreaseCustomizeItemQty({}));
                                }else{
                                    dispatch(resetCustomizeItem());
                                    handleClose();
                                }
                            }else{
                                dispatch(resetCustomizeItem());
                                handleClose();
                            } 
                        }}>
                            <Iconify icon="fa:minus" color="#000" />
                        </Button> 
                    </Grid>
                    <Grid item sx={{justifyContent:'center',paddingLeft:2, paddingRight: 2}}>
                        <Typography variant="h4" color="text.primary" style={{color:'#000'}} >
                            {customizedQty}
                        </Typography>
                    </Grid>
                    <Grid item sx={{padding:0}}>
                        <Button className="change-qty-customization" onClick={(e) => {
                            console.log(customizedQty);
                            console.log(nonCustomizedItemQty);
                            console.log(customizedItem.item.max_quantity);
                            if (customizedQty + nonCustomizedItemQty < customizedItem.item.max_quantity) {
                                dispatch(increaseCustomizeItemQty());
                            }else{
                                console.log(customizedItem);
                                const message = `You can order a maximum of ${customizedItem.item.max_quantity} units at a time.`;
                                
                                dispatch(setSnackbarMessage({'message': message, 'severity': SNACKBAR_SEVERITY.INFO}));
                                dispatch(setSnackbarVisibilityStatus({'status': true }));
                            }
                        }}>
                            <Iconify icon="fa:plus" color="#000" /> 
                        </Button>
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={() => {
                    dispatch(handleAddCustomizeToCart({'item': customizedItem}));
                }}>
                    <Grid justifyContent={'center'} sx={{color:'#fff', padding:1, borderRadius: 1, paddingLeft: 1, paddingRight: 1}}>
                        <Typography variant="h5">Add item | {`₹ ${customizedPrice}`} </Typography>
                    </Grid>
                </Button>
            </Grid>
        );
    }

    const handleAddonToggle = (addon, element) => {
        try{
            let quantity = 1;
            if(element.target.checked){
                // add
                quantity = 1
            }else{
                quantity = 0
            } 
            dispatch(updateCustomizeItem({'addon' : addon, 'quantity' : quantity}));
        }catch(err){
            console.log(err);
        }  
    }

    const handleAddCustomizeToCart = () => {

        // check if same combination exists in cart
        // if yes increase qty by cart qty
        // let cartQty = 0;

        try{
            if (editingCartItemId !== undefined && editingCartItemId !== null){
                updateCartApi(dispatch, cookies, {     
                    'cart_item_id' : editingCartItemId,
                    'schedule_id' : customizedItem.item.schedule_id, 
                    'item' : customizedItem.item,
                    'quantity': customizedItem.qty,
                    'addon' : customizedItem.addon
                });
            }else{
                let cartQty = 0
                cartItems.forEach(cartItem => {
                    if (cartItem.schedule_id === customizedItem.item.schedule_id) {
                        let matchedAddon = 0;
                        customizedItem.addon.forEach(customizedAddon => {
                            cartItem.addons.forEach(addon => {
                                if (addon.schedule_id === customizedAddon.addon.schedule_id) {
                                    if (customizedAddon.qty === addon.quantity){
                                        matchedAddon += 1;
                                    }
                                }
                            });
                        });

                        if (matchedAddon === customizedItem.addon.length) {
                            cartQty = cartItem.quantity;
                        }
                    }
                });

                updateCartApi(dispatch, cookies, {              
                    'item': customizedItem.item, 
                    'quantity': cartQty + customizedItem.qty,
                    'addon' : customizedItem.addon
                });
            }
        }catch(err){
            console.log(err);
        }

        // updateCartApi(dispatch, cookies, {              
        //     'item': customizedItem.item, 
        //     'quantity': cartQty + customizedItem.qty,
        //     'addon' : customizedItem.addon
        // });

        handleClose();
    }
    
    const handleAddonQtyChange = (item, addon, qty) => {
        try{
            const addonList = [];

            /**
             * all addon list : customizedItem.item.addons
             * selected addon list and qty : customizedItem.addon
             * change Qty => customizedItem.addon
             * 
             */

            item.addons.forEach(addonElement => {

                if (addonElement.schedule_id === addon.schedule_id){
                    // this addon qty need to be changed
                    addonList.push({
                        'addon': addonElement,
                        'quantity': qty
                    });
                }else{
                    // this addon to be remained same if exists
                    const selectedAddon = customizedItem.addon.find(i => i.addon.schedule_id === addonElement.schedule_id);
                    if (selectedAddon !== undefined){
                        console.log(selectedAddon);
                        addonList.push({
                            'addon': addonElement,
                            'quantity': selectedAddon.quantity
                        });
                    }
                }
            });

            dispatch(setCustomizeItem({
                'item' : item,
                'addons' : addonList,
                'quantity': customizedItem.qty,
            }));
            
        }catch(err){
            console.log(err);
        }
    }

    const getAddonDetail = (addon) => {
        try{
            const addonElement = customizedItem.addon.find(item => item.schedule_id === addon.schedule_id);
            return addonElement;
        }catch(e){
            console.log(e);        
        }
        return null;
    }

    const getAddonQuantity = (addon) => {
        try{
            const addonElement = customizedItem.addon.find(item => item.addon.schedule_id === addon.schedule_id);
            if (addonElement){
                return addonElement.quantity;
            }
        }catch(e){
            console.log(e);        
        }
        return 0;
    }

    return (
        <BottomSheet 
            className="customize-modal"
            onDismiss={() => {handleClose()}}
            header={<CustomizeOptionHeader item={item} />}
            footer={<CustomizeOptionFooter item={item} />}
            style={{
                // @ts-ignore
                "--rsbs-bg": '#f2f6fc',
            }}
            open={isOpen}
        >
            <Grid flexDirection="column" justifyContent="flex-start" alignItems="flex-start" container style={{minHeight:'300px',padding:10}}>                    
                
                <Box margin={1} />
                {
                    customizedItem && customizedItem.item && customizedItem.item.addons && customizedItem.item.addons.length > 0 &&
                    <Grid item container>
                        <Typography variant="h5">Add-ons</Typography>

                        <Grid item container className='addon-container' rowGap={1}>
                            {
                                customizedItem.item.addons.map((addon) => {
                                    return <Grid item container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}  maxWidth={'60%'} columnGap={1}>
                                            {
                                                addon.is_non_veg
                                                ? <img height={24} width={24} src='/static/nonveg.png' alt='non veg' />
                                                : <img height={24} width={24} src='/static/veg.png' alt='veg' />
                                            }
                                            <Typography variant="body1">
                                                {addon.display_name} ({`₹${addon.effective_price}/unit`})
                                            </Typography>
                                        </Grid>

                                        {
                                            (addon && getAddonQuantity(addon) === 0)
                                            ? <Grid item container flexDirection={'row'} justifyContent={'center'} alignItems={'center'} maxWidth={'40%'}>
                                                <Button variant='outlined' sx={{width:'90px', height: '30px'}} onClick={() => {
                                                    handleAddonQtyChange( customizedItem.item , addon,  1);
                                                }}>
                                                    Add
                                                </Button>
                                            </Grid>
                                            : <Grid item container flexDirection={'row'} justifyContent={'center'} alignItems={'center'} maxWidth={'40%'}>
                                                    
                                                    <Grid container spacing={0}
                                                        direction="row"
                                                        justifyContent="center"
                                                        flexWrap={"nowrap"}
                                                        alignItems="center"
                                                        sx={{padding:0}}
                                                        className="change_qty"
                                                    >
                                                        <Grid item sx={{padding:0}}>
                                                            <Button className="shopping__icon" onClick={() => {
                                                                handleAddonQtyChange( customizedItem.item , addon,  addon && getAddonQuantity(addon) - 1);   
                                                            }}>
                                                                <Iconify icon="fa:minus" color="#fff" />
                                                            </Button>
                                                        </Grid>
                                                        <Grid item sx={{justifyContent:'center',paddingLeft:2, paddingRight: 2}}>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {addon && getAddonQuantity(addon)}
                                                                {/* {customizedItem.addon.find(addonItem => addonItem.schedule_id === addon.schedule_id)} */}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item sx={{padding:0}}>
                                                            <Button className="shopping__icon" onClick={(e) => {
                                                                handleAddonQtyChange( customizedItem.item , addon, addon && getAddonQuantity(addon) + 1);
                                                            }}>
                                                                <Iconify icon="fa:plus" color="#fff" /> 
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </Grid>
                                    }
                                )
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        </BottomSheet>
    );
}