import { setLoadingStatus } from '../../../store/layout/reducer';
import { updateCart } from '../../../store/cart/reducer';
import axios from '../../../utils/axios-configure';
import { API_UPDATE_CART_CORPORATE, API_GET_CART_CORPORATE } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api';
import { CHECKOUT_TYPE } from '../../../constants/app_constants';

export const updateCartApi = (dispatch, cookies, data) => {
    // eslint-disable-next-line no-undef
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies ); 
    dispatch(setLoadingStatus({status: true}));  

    console.log(data);
    
    if(accessToken != null){ 
      console.log(data);
        const { item, quantity, addon } = data

        const cartItemId = data.cart_item_id;
        const addonList = [];

        let checkoutType = CHECKOUT_TYPE.STANDARD;
        if (data.checkout_type != null ){
          checkoutType = data.checkout_type;
        }

        if (addon != null){
          addon.forEach((e) => {
            addonList.push({
              'schedule_id' : e.addon.schedule_id,
              'quantity' : e.quantity,
            });
          })
        }
        axios({
          method: "post",
          url: API_UPDATE_CART_CORPORATE,
          data: {
            "cart_item_id" : cartItemId,
            "schedule_id" : item.schedule_id,
            "quantity" : quantity,
            "addons" : addonList,
            "checkout_type" : checkoutType
          },
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => { 
          // handle success
            if(response.status === 200){
              dispatch(updateCart({data: response.data.data}));
            }else{
    //          displayMessage("error","Unable to add item to cart");
            }
            dispatch(setLoadingStatus({status: false})); 
        })
        .catch((response) => {  
          dispatch(setLoadingStatus({status: false}));   
        });
    }else{
      dispatch(setLoadingStatus({status: false}));   
    }
}


export const fetchCart = (dispatch, cookies, ) => {
    dispatch(setLoadingStatus({status: true}));  
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies ); 
    axios({
      method: "get",
      url: API_GET_CART_CORPORATE,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      if(response.status === 200){
        console.log(response);
        dispatch(updateCart({data: response.data.data}));
      }else{ 
          //
      }
      dispatch(setLoadingStatus({status: false}));  
    })
    .catch((response) => {  
      dispatch(setLoadingStatus({status: false})); 
    });
  }


  // Quick Checkout - add to cart
export const quickCheckoutAddToCart = (dispatch, cookies, data) => {
  // eslint-disable-next-line no-undef
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies ); 
  dispatch(setLoadingStatus({status: true}));  
  
  if(accessToken != null){ 
    
      axios({
        method: "post",
        url: API_UPDATE_CART_CORPORATE,
        data: {
          "schedule_id" : data.schedule_id,
          "quantity" : data.quantity,
          "addons" : data.add_ons,
          "checkout_type" : data.checkout_type,
        },
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => { 
        // handle success
          if(response.status === 200){
            console.log(response.data.data);
            dispatch(updateCart({data: response.data.data}));
          }else{
  //          displayMessage("error","Unable to add item to cart");
          }
          dispatch(setLoadingStatus({status: false})); 
      })
      .catch((response) => {  
        dispatch(setLoadingStatus({status: false}));   
      });
  }else{
    dispatch(setLoadingStatus({status: false}));   
  }
}
