import { faker } from '@faker-js/faker';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, TextField, Container, Typography, Box, Button, Fab, FormGroup, FormControlLabel, Switch, Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useIsInViewport from 'use-is-in-viewport'
import {motion} from 'framer-motion';

// import TinderCard from 'react-tinder-card';
import styled from 'styled-components';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import  '../assets/css/Menu.css';
// sections
import { CategoryList, MenuList, MyCalendar, OrderSummary } from '../sections/menu';
import { getSelectedAccountType, getSelectedCorporate } from '../store/otp/reducer';
import { fetchMenu } from '../data/network/menu/api';
import { fetchCart } from '../data/network/cart/api';
import { ACCOUNT_TYPE } from '../constants/app_constants';
import { formatDate } from '../utils/date';
import { filterAdded, filterRemoved, getSelectedFilter, getSelectedService, getVegPreferenceFilter, setSelectedDate } from '../store/menu/reducer';
import { getLoginStatus, logoutUser } from '../store/user/reducer';
import ServiceList from '../sections/menu/ServiceList';
import { getCartItems, getGrandTotal } from '../store/cart/reducer';
import SearchMenu from '../sections/menu/SearchMenu';
import FeedbackComponent from '../sections/feedback/feedback_component';
import { setFeedbackComponentVisibility } from '../store/feedback/reducer';
import Navbar from '../components/Navbar';
import ToggleCorporateList from '../sections/menu/ToggleCorporateList';

// ----------------------------------------------------------------------

export default function Menu() {
  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const selectedAccountType = useSelector(state => getSelectedAccountType(state));
  const selectedCorporate = useSelector(state => getSelectedCorporate(state));
  const selectedService = useSelector(state => getSelectedService(state));
  const isLoggedIn = useSelector(state => getLoginStatus(state));

  const cartItems = useSelector(state => getCartItems(state));
  const grandTotal = useSelector(state => getGrandTotal(state));  

  const [isCorporateListVisible, setIsCorporateListVisible] = useState(false);

  const ref = useRef(null);
  const menuRef = useRef(null);  

  const [parentViewport, parentViewportTargetRef, viewportRefToChain] = useIsInViewport({
    threshold: 50
  })
  const [inCartFabinViewport, cartFabTargetRef, viewportRef] = useIsInViewport({
    viewport: viewportRefToChain,
    threshold: 75
  })

  const isVegPreferred = useSelector(state => getVegPreferenceFilter(state));

 
  const toggleVegPreference = () => {
      if(!isVegPreferred) {
          dispatch(filterAdded({type: 'preference', value: 'veg'}));
      }else{
          dispatch(filterRemoved({type: 'preference', value: 'veg'}))
      }
  }


  useEffect(() => { 
    if(!isLoggedIn){ 
      navigate('/');
    }
  },[isLoggedIn])
  
  useEffect(() => {
    console.log(selectedAccountType);
    console.log(selectedService);
    if(selectedAccountType === ACCOUNT_TYPE.CORPORATE){
      let serviceId = 1;
      try{
        if (selectedService != null) {
          serviceId = selectedService.id
        }
      }catch(e){
        console.log(e);
      }

      handleMenuDateChange(Date());

      fetchMenu(dispatch, cookies, {
          org: selectedCorporate.name,
          date: formatDate(Date(), "DD-MM-yyyy"),
          serviceId
      });

      fetchCart(dispatch, cookies);
    }else{
      console.log(selectedAccountType);
      console.log(selectedService);
    }
  },[selectedService, selectedAccountType, selectedCorporate])
  
  useEffect(() => {
    // dispatch(setFeedbackComponentVisibility({isVisible : true}));
  },[])

  useEffect(() => {
    // setFilter([{key: 'category', value: "all"}])
    dispatch(filterAdded({type:'category', value:'all'}));
  }, []);

  
  const [menuDate, setMenuDate] =  useState(
    new Date() 
  );

  const handleMenuDateChange = (newValue ) => {
    try{
      setMenuDate(newValue); 
      dispatch(setSelectedDate(newValue));

      let serviceId = 1;
      try{
        if (selectedService != null) {
          serviceId = selectedService.id
        }
      }catch(e){
        console.log(e);
      }


      fetchMenu(dispatch, cookies, {
          org: selectedCorporate.name,
          date: formatDate(newValue, "DD-MM-yyyy"),
          serviceId
      });
    }catch(e) {
      console.log(e);
    }
  };

  const handleDateChange = (type) => {
    const todayObj = new Date();
    const menuDateObj = new Date(menuDate);
    if(type === "prev"){
      if(todayObj < menuDateObj){
        menuDateObj.setDate(menuDateObj.getDate() - 1); 
      }
    }else{
        menuDateObj.setDate(menuDateObj.getDate() + 1);  
    }
    // var tomorrow = format(tomorrow_obj, "yyyy-MM-dd");
    // console.log(tomorrow_obj);
    handleMenuDateChange(menuDateObj);

    // setMenuDate(tomorrow);
  }

  const onSwipe = (direction) => {
    console.log( direction)
  }
  
  const onCardLeftScreen = (myIdentifier) => {
    console.log( ' left the screen')
  }
  
  return (
    <Page title="Menu">
        <Grid container justifyContent={'space-evenly'} columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>          
          <Grid className="flexbox-wrapper" item lg={12} sm={12} md={12} xl={12} sx={{overflowY:'scroll'}}>              
              <Grid 
                className="navbar-web"
                justifyContent={'flex-start'}
                alignItems={'center'}
                sx={{
                  // boxShadow: '0px -7px 20px 3px #0000006b',
                  // backgroundColor: '#e86826'
                }}
              >
                <Grid container className="header-logo" lg={2} md={2} xl={2} xs={0} sm={0} item>
                  <a href='/'>
                    <img src='../../static/logo.png' alt='Bytes' width={96} />
                  </a>
                </Grid>

                <Grid container className="menu-location-picker glass-box" padding={1} lg={2} md={2} xl={2} xs={12} sm={12} alignItems={"center"} item marginBottom={0}>
                  <Iconify icon="eva:pin-fill" fontSize={24} sx={{color:'#000'}} mr={1} />
                  <Typography variant={'body1'} sx={{color: '#000'}}>
                    {
                      selectedCorporate
                      ? selectedCorporate.name
                      : "Select location"
                    }
                  </Typography>
                  <Iconify icon="eva:arrow-ios-downward-outline" fontSize={24} ml={1} sx={{color:'#000'}} />
                </Grid> 

                <Grid container lg={4} md={4} sm={12} xl={4} xs={12}>
                    {/*  */}
                </Grid> 

                <Grid container order={{lg:2, xl:2, sm:3, md:2, xs:3}} item lg={4} md={4} sm={12} xl={4} xs={12}>
                  <SearchMenu />
                </Grid>

                <Grid  order={{lg:3, xl:3, sm:2, xs:2, md:3}} justifyContent={'center'} alignItems={'center'} container item lg={2} md={3} sm={3} xl={2} xs={3} rowGap={1}>
                  {/* <Grid className={'menu-icon menu-icon-notification'} sx={{}}>
                    <Iconify icon={'eva:bell-outline'} sx={{color:'#fff', fontSize:'24px'}} />
                  </Grid> */}
                  <Grid className={'menu-icon menu-icon-profile glass-box'} padding={2} sx={{}}>
                    <Iconify icon={'eva:people-outline'} sx={{color:'#000', fontSize:'24px'}} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid 
                className="navbar-mobile"
                justifyContent={'flex-start'}
                alignItems={'center'}
                sx={{
                  boxShadow: '0px -7px 20px 3px #0000006b',
                  backgroundColor: '#e86826'
                }}
              >
                <Grid container flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'flex-end'}>
                  <Grid container item flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} lg={6} md={6} sm={8} xl={6} xs={8}>
                    <Grid container className="header-logo" lg={3} md={3} xl={3} xs={0} sm={0} item>
                      <a href='/'>
                        <img src='../../static/logo.png' alt='Bytes' width={96} />
                      </a>
                    </Grid>

                    <Grid container lg={9} md={9} xl={9} xs={11} sm={11} order={1} paddingLeft={1} justifyContent={'center'} alignItems={'flex-start'}>
                      {/* <Typography variant={'body1'}>
                        {'Location'}
                      </Typography> */}
                      <Grid container className="menu-location-picker" lg={12} md={12} xl={12} xs={12} sm={12} alignItems={"center"} item marginBottom={1}>
                        <Iconify icon="eva:pin-fill" fontSize={24} sx={{color:'#fff'}} mr={1} />
                        <Typography variant={'body1'} sx={{color: '#fff'}}>
                          {
                            selectedCorporate
                            ? selectedCorporate.name
                            : "Select location"
                          }
                        </Typography>
                        <Iconify icon="eva:arrow-ios-downward-outline" fontSize={24} ml={1} sx={{color:'#fff'}} />
                      </Grid> 
                    </Grid>

                  </Grid>

                  <Grid container order={{lg:2, xl:2, sm:3, md:2, xs:3}} item lg={4} md={4} sm={12} xl={4} xs={12}>
                    <SearchMenu />
                  </Grid>

                  <Grid  order={{lg:3, xl:3, sm:2, xs:2, md:3}} justifyContent={'center'} alignItems={'center'} padding={1} container item lg={2} md={3} sm={3} xl={2} xs={3} rowGap={1}>

                    {/* <Grid className={'menu-icon menu-icon-notification'} sx={{}}>
                      <Iconify icon={'eva:bell-outline'} sx={{color:'#fff', fontSize:'24px'}} />
                    </Grid> */}
                    <Grid className={'menu-icon menu-icon-profile'} sx={{}}>
                      <Iconify icon={'eva:people-outline'} sx={{color:'#fff', fontSize:'24px'}} />
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
              <FeedbackComponent />
              <Grid className='menu-container' columnGap={2} paddingBottom={10}>
                
                <Grid item container xs={12} sm={12} md={12} lg={9} xl={9}>
                  <Grid container className="scrolling-wrapper-flexbox-web">
                    <Grid 
                        className={`scrolling-wrapper-card ${isVegPreferred ? 'active' : ''}`}
                        onClick={toggleVegPreference}
                    >
                        <Typography variant="body1">Show Veg only</Typography>
                        {isVegPreferred && <Iconify icon={'eva:close-outline'} sx={{color:'#fff', fontSize:'18px', marginLeft:'8px'}} />}
                    </Grid>
                  </Grid>

                  <MenuList  />
                </Grid> 

                <Grid className={'menu-order-summary'} item container xs={0} sm={0} md={0} lg={3} xl={3}>
                  <OrderSummary />
                </Grid>
              </Grid>
              
              {cartItems.length > 0 && <Grid className='fab-cart-container' style={{display: parentViewport ? 'none' : 'flex'}}>
                    <Grid 
                        container 
                        flexDirection='row' 
                        justifyContent='space-between' 
                        style={{color:'#fff',fontSize:'16px'}}
                        onClick={() => {
                          navigate('/cart');
                          // ref.current?.scrollIntoView({behavior: 'smooth', top:180});
                        }}
                        ref={cartFabTargetRef}
                    >
                      <p>
                        {cartItems.length} items | ₹{grandTotal}
                      </p>
                      <span>View Cart</span>
                    </Grid>
                  </Grid>
                }
          </Grid>
        </Grid>
    </Page>
  );
}
