import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
//
import { Box, Stack, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { getSession } from '../data/network/session/api';
import { getLoginStatus, getUser, logoutUser, setActiveCorporateClient } from '../store/user/reducer';
import { fetchUserDetails } from '../data/network/user/api';
import { getSideNavigationMenuConfig } from '../utils/nav_helper';
import BottomNavSection from './BottomNavSection';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // display: 'flex',
//   minHeight: '240px',
  // overflow: 'hidden', 
  justifyContent: 'center',
  alignItems: 'center',
  // borderTopLeftRadius : '50px',
  // borderTopRightRadius : '50px',
//   border: '1px solid #e0e0e0',
//   borderRadius: '10px',
  // background: theme.palette.gradients.primary,
  // borderTop: '1px solid rgba( 255, 255, 255, 0.6 )',
//   boxShadow: '8px -8px 32px 0 rgba( 0, 0, 0, 0.18 )',
// 
//   WebkitFilter: 'blur(10px)',
}));

const MainContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
//   minHeight: '240px',
  display:'flex',
  background: 'red',
//   backdropFilter: 'blur(10px)',
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(28),
    // paddingRight: theme.spacing(2),
    // display:'none!important',
}
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  display:'flex',
//   minHeight: '240px',
backgroundColor: 'green',
// display:'none',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // display: 'none!important',
    // backgroundColor: 'green',
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function BottomNavbar({showHeader=true, showFooter=true}) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const navigate = useNavigate();



  return (
    <RootStyle>
      <BottomNavSection navConfig={getSideNavigationMenuConfig()} />
    </RootStyle>
  );
}
