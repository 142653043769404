import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import Footer from '../components/Footer';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden', 
  flexDirection:'column' 
});

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    // padding: theme.spacing(5, 5, 0),
  },
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '90vh',
  width:'100vw',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
})); 

const FooterStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // overflow: 'auto',
  width:'100%',
  flexShrink: 0,
  textAlign: 'center',
  // backgroundColor: 'red',
  color: 'white',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // }
})); 

// ----------------------------------------------------------------------
export default function LogoOnlyLayout() {
  return (
    <RootStyle>
      <HeaderStyle>
        <a href='/'>
          <img src='../../static/logo.png' alt='Bytes' width={100} />
        </a>
      </HeaderStyle>
      <MainStyle> 
        <Outlet />
      </MainStyle>
      <Footer/>
      {/* <FooterStyle>
        <Footer/> 
      </FooterStyle> */}
    </RootStyle>
  );
}
