import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams, Link as RouterLink, useNavigate } from 'react-router-dom'; 
import Select from 'react-select';

// material
import { Link,Box, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Container, Card, Grid, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";
 
// component
import Iconify from '../../../components/Iconify';
import { ACCOUNT_TYPE, ROLES } from '../../../constants/app_constants'; 
import AccountTypeWidget from './AccountTypeWidget';
import AccountTypeHeadWidget from './AccountTypeHeadWidget';
import { setSelectedAccountType, setSelectedCorporate, clearOTPState, getSelectedCorporate, setSelectedSubCorporate, setSelectedTableId } from '../../../store/otp/reducer';

import '../../../assets/css/Login.css';
import { fetchCorporateList } from '../../../data/network/corporate/api';
import { getCorporateList } from '../../../store/corporate/reducer';
// ----------------------------------------------------------------------

export default function SelectCorporate() {
  const navigate = useNavigate(); 
 
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const selectedCorporate = useSelector(state => getSelectedCorporate(state));
  const [options, setOptions] = useState([]);
  const corporateList = useSelector(state => getCorporateList(state));

  const [searchParams, setSearchParams] = useSearchParams();

  const corporateId = searchParams.get('corporate_id');
  const tableId = searchParams.get('table_id');
  const subCorporateId = searchParams.get('sub_corporate_id');

  useEffect(() => {
    fetchCorporateList(dispatch);
  },[]);

  useEffect(() => {
    if(corporateList != null){
      const corporates = [];
      corporateList.forEach(element => {
        corporates.push({
          'key' : element.id,
          'label' : element.display_name
        });
      });

      setOptions(corporates);

      if (corporateId !== null){
        for (let i = 0; i <corporates.length; i+=1) {
          if (corporates[i].key.toString() === corporateId.toString()) {
            handleCorporateSelection(corporates[i]);

            if (subCorporateId === null) {
              // dispatch(setSelectedSubCorporate({'subCorporate': corporates[i]}));
              handleSubCorporateSelection(corporates[i]);
            }
          }
        }
      }
      
      if (subCorporateId !== null){
        for (let i = 0; i < corporates.length; i+=1) {
          if (corporates[i].key.toString() === subCorporateId.toString()) {
            handleSubCorporateSelection(corporates[i]);
          }
        }
      }
    };
  },[corporateList]);

 
  const handleCorporateSelection = (corporate) => {
    setSelectedOption(corporate); 

    corporateList.forEach(element => {
      if(element.id === corporate.key){
        dispatch(setSelectedCorporate({'corporate': element}));
        
        console.log("!11111111");
        console.log(subCorporateId);
        if (subCorporateId === null) {
          dispatch(setSelectedSubCorporate({'subCorporate': element}));
        }
      }
    });
  }

  const handleSubCorporateSelection = (corporate) => {    
    corporateList.forEach(element => {
      if(element.id === corporate.key){
        console.log(element);
        dispatch(setSelectedSubCorporate({'subCorporate': element}));
      }
    });
  }

  const selectStyles = {
    control: base => ({
      ...base,
      height: 35,
      minHeight: 35
    })
  };
 

  useEffect(()=>{
    if(selectedCorporate!=null){
      console.log(selectedCorporate);
//      dispatch(setSelectedCorporate({'corporate': element}));
    //  setSelectedCorporate(selectedCorporate);
    }
  },[selectedCorporate])

  
  useEffect(()=>{
    if(tableId!=null){
      dispatch(setSelectedTableId({'tableId' : tableId}));
    }
  },[tableId])
 
  return (
    <Grid container justifyContent="center" padding={2} spacing={3}> 
        <Grid item container xs={12} sm={12} md={12} justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6} md={6} style={{cursor:'pointer'}} >
              <Card
                  className='glass-box'
                  sx={{
                    py: 5,
                    px: 5,
                    height: '60vh',
                    boxShadow: 0,
                    textAlign: 'center',
                    color: '#000',
                    bgcolor: '#eee',       
                  }}                   
                >
                {/* <Grid container direction="column" justifyContent="start" alignItems="start" > 
                  <Button variant='contained'  onClick={() => { 
                      dispatch(setSelectedAccountType({type: null}));
                      dispatch(clearOTPState( ));
                    }}
                  > 
                    <Grid className container justifyContent="start" alignItems="center"
                        style={{cursor:'pointer'}}> 
                        <Iconify icon="bytesize:arrow-left" />
                        <Box padding={0.5}/>
                        <span>Go Back</span>
                    </Grid>
                  </Button>
                </Grid> */}
                <Box margin={4}/>
                <Grid container direction="column" justifyContent="center" alignItems="center"  
                    >              
                    <h2>Welcome to </h2>
                    <h2>Corporate Orders!</h2>
                </Grid>
                <Box margin={4}/>
                <Grid container justifyContent="start" alignItems="center"  
                    >              
                    <span>Please select your organization from below:</span>
                </Grid>
                <Box margin={1}/>
                <Select
                  classNamePrefix="corporate-select"
                  defaultValue={selectedOption}
                  onChange={(e) => handleCorporateSelection(e)}
                  options={options}
                  menuPortalTarget={document.body}
                  style={selectStyles}
                />
              </Card>
            </Grid> 
        </Grid> 
    </Grid> 
  );
}
