import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom'; 
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Container, Card, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";
 
// component
import Iconify from '../../../components/Iconify';
import { ACCOUNT_TYPE, ROLES } from '../../../constants/app_constants'; 
import AccountTypeWidget from './AccountTypeWidget';
import AccountTypeHeadWidget from './AccountTypeHeadWidget';
import { setSelectedAccountType } from '../../../store/otp/reducer';

// ----------------------------------------------------------------------

export default function SelectAccountType() {
  const navigate = useNavigate(); 
 
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();

  const handleAccountSelection = (accountType) => {
    dispatch(setSelectedAccountType({type: accountType})); 
  }
 
  return (
    <Grid container justifyContent="center" padding={2} spacing={3}>

        <Grid item container xs={12} sm={12} md={12} justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={6} md={3} >
            <AccountTypeHeadWidget />
          </Grid>
        </Grid>

        <Grid item container xs={12} sm={12} md={12} justifyContent="center" alignContent ="center" >
            <Typography variant='h6'>
                Please choose from the following
            </Typography> 
        </Grid>

        <Grid item container xs={12} sm={12} md={12} justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={6} md={3} style={{cursor:'pointer'}} onClick={() => {
              handleAccountSelection(ACCOUNT_TYPE.PERSONAL);
          }}>
            <AccountTypeWidget title="Personal Orders" sx={{ }} icon="fa-user" />
          </Grid>

          <Grid item xs={12} sm={6} md={3} style={{cursor:'pointer'}} onClick={() => {
              handleAccountSelection(ACCOUNT_TYPE.CORPORATE);
          }}>
              <AccountTypeWidget title="Corporate Orders" sx={{ }} icon="fa-briefcase" />
          </Grid> 
        </Grid> 
    </Grid> 
  );
}
