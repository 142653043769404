import { createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../../constants/app_constants';

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscriptionPlanList: [],
    subscriptionPlanListStatus : STATUS.LOADING,
  },
  reducers: {
    setSubscriptionPlanList(state, action) {
      state.subscriptionPlanList = action.payload.data;
    },
    setSubscriptionPlanListStatus(state, action) {
      state.subscriptionPlanListStatus = action.payload.status;
    },
  }
})

export const getSubscriptionPlanList = (state) => state.subscription.subscriptionPlanList

export const getSubscriptionPlanListStatus = (state) => state.subscription.subscriptionPlanListStatus

export const { setSubscriptionPlanList, setSubscriptionPlanListStatus } = subscriptionSlice.actions

export default subscriptionSlice.reducer