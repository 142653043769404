import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { API_TERMS_AND_CONDITIONS } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { TERMS_AND_CONDITIONS } from '../../../constants/message_constants';
import { setTermsAndConditions } from '../../../store/about/reducer';

export const fetchTermsAndConditions = (dispatch) => {
  dispatch(setLoadingStatus({status: true}));

  try{
      axios({
          method: "get",
          url: `${API_TERMS_AND_CONDITIONS}`,
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){ 
            dispatch(setTermsAndConditions({data: response.data}));
          }else{
            dispatch(setSnackbarMessage({message: TERMS_AND_CONDITIONS.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
            dispatch(setSnackbarVisibilityStatus({status: true}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({status: false}));  
          dispatch(setSnackbarMessage({message: TERMS_AND_CONDITIONS.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
          dispatch(setSnackbarVisibilityStatus({status: true}));
        // console.log(response);
        });
        dispatch(setLoadingStatus({status: false}));  

    }catch(e){
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({message: TERMS_AND_CONDITIONS.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
        dispatch(setSnackbarVisibilityStatus({status: true}));

        console.log(e);
    }

    dispatch(setLoadingStatus({status: false}));  

}