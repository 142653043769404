import axios from 'axios';
import {API_BASE_URL} from '../data/network/endpoints';

const app = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

export default app;