import { createSlice } from '@reduxjs/toolkit'

const orderSlice = createSlice({
  name: 'order',
  initialState: {
      orderDetails: null, 
  },
  reducers: {
    setActiveOrderDetails(state, action) {
        state.orderDetails = action.payload.data;
    }, 
  }
})

export const getActiveOrderDetails = (state) => {
    if(state.order && state.order !== null){
        return state.order.orderDetails; 
    }
    return null
}
 

export const { setActiveOrderDetails } = orderSlice.actions
export default orderSlice.reducer