 import {  setClientOrderDetails } from '../../../store/client/reducer';
import axios from '../../../utils/axios-configure';
import { API_GENERATE_ORDER_ID, API_ADD_TRANSACTION, API_GENERATE_ORDER_ID_CORPORATE,API_CONFIRM_ORDER_CORPORATE,  API_ADD_TRANSACTION_CORPORATE, API_GET_CORPORATE_ORDER_DETAILS } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'
import { getOrderDetails, increasePhonepeStatusCheckCount, setAddTransactionDetails, setOrderDetails, setPaymentAck, setPaymentStatus } from '../../../store/payment/reducer';
import { resetCart, setSocketUrl, updateCart } from '../../../store/cart/reducer';
import { setActiveOrderDetails } from '../../../store/order/reducer';
import { PAYMENT_STATUS } from '../../../constants/app_constants';
import { setLoadingStatus } from '../../../store/layout/reducer';

export const generateOrderId = (dispatch, cookies, data) => {
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies, data); 
    if(accessToken != null){
        axios({
            method: "post",
            url: `${API_GENERATE_ORDER_ID}`,
            data,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            console.log(response);
            if(response.status === 200){
              dispatch(setClientOrderDetails({data: response.data.data}));
            }else{
              dispatch(setClientOrderDetails({data: null}));
            } 
            return true;
          })
          .catch((err) => {
            dispatch(setClientOrderDetails({data: null}));
          });
    }else{
      dispatch(setClientOrderDetails({data: null}));
    }
}

export const addTransaction = (dispatch, cookies, data) => {
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "post",
            url: `${API_ADD_TRANSACTION}`,
            data,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
              console.log(response);
            // handle success
            if(response.status === 200){
              dispatch(setAddTransactionDetails({data: response.data.data}));              
            }else{
              dispatch(setAddTransactionDetails({data: null}));    
            }
            return true;
          })
          .catch((err) => {
            dispatch(setAddTransactionDetails({data: null}));    

          });
    }else{
      dispatch(setAddTransactionDetails({data: null}));    
    }
}

export const generateCorporateOrderId = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies, data); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_GENERATE_ORDER_ID_CORPORATE}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          console.log(response);
          if(response.status === 200){
            dispatch(setSocketUrl({url: response.data.socket_host}));
            dispatch(setOrderDetails({data: response.data.data}));
          }else{
            dispatch(setOrderDetails({data: null}));
          } 
          return true;
        })
        .catch((err) => {
          dispatch(setOrderDetails({data: null}));
        });
  }else{
    dispatch(setOrderDetails({data: null}));
  }
}

export const addCorporateTransaction = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_ADD_TRANSACTION_CORPORATE}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
            console.log(response);
          // handle success
          if(response.status === 200){
            dispatch(setAddTransactionDetails({data: response.data.data}));              
          }else{
            dispatch(setAddTransactionDetails({data: null}));    
          }
          return true;
        })
        .catch((err) => {
          dispatch(setAddTransactionDetails({data: null}));    

        });
  }else{
    dispatch(setAddTransactionDetails({data: null}));    
  }
}

export const confirmCorporateOrder = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_CONFIRM_ORDER_CORPORATE}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
      // handle success
      if(response.status === 200){ 
        dispatch(setPaymentAck({data: {'status' : "success", 'order_id' : data.order_id,  'transaction_id' : data.transaction_id, 'amount': data.grand_total}}));
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.CONFIRMED }));
        dispatch(resetCart());
        // dispatch(updateCart({data: {
        //   'cartItems': [],
        //   'couponCode' : "",
        //   'priceBreakdown' : [],
        //   'isCartValid': false,
        //   'invalidMsg': ""
        // }}));
      }else{
        dispatch(setPaymentAck({data: {'status' : "error", 'order_id' : data.order_id, 'transaction_id' : data.transaction_id, 'amount': data.grand_total}})); 
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED }));
      }
      return true;
    })
    .catch((err) => {
      dispatch(setPaymentAck({data: {'status' : "error", 'order_id' : data.order_id, 'transaction_id' : data.transaction_id, 'amount': data.grand_total}})); 
      dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED }));
    });
  }else{
    dispatch(setPaymentAck({data: {'status' : "error", 'order_id' : data.order_id, 'transaction_id' : data.transaction_id, 'amount': data.grand_total}})); 
    dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED }));
  }
}

export const fetchCorporateOrderDetails = async (dispatch, cookies, orderId) => {
  dispatch(setLoadingStatus({status: true}));
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  
  if(accessToken != null){
    axios({
        method: "get",
        url: `${API_GET_CORPORATE_ORDER_DETAILS}?order_type=corporate&order_id=${orderId}`, 
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => {
          console.log(response);
        // handle success
        if(response.status === 200){ 
          dispatch(setActiveOrderDetails({data: response.data.data[0]} ));
          
        }else{
          dispatch(setActiveOrderDetails({data: {} } ));  
        }
        dispatch(setLoadingStatus({status: false}));
        return true;
      })
      .catch((err) => {
        dispatch(setActiveOrderDetails({ data: {}} )); 
        dispatch(setLoadingStatus({status: false}));
      });
  }else{
    dispatch(setActiveOrderDetails({data: {} } ));
    dispatch(setLoadingStatus({status: false}));
  } 
}

export const checkPhonepePaymentStatus = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_CONFIRM_ORDER_CORPORATE}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
      // handle success
      if(response.status === 200){ 
        dispatch(setPaymentAck({data: {'status' : "success", 'order_id' : data.order_id,  'transaction_id' : data.transaction_id, 'amount': data.grand_total}}));
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.CONFIRMED }));
        dispatch(resetCart());
        // dispatch(updateCart({data: {
        //   'cartItems': [],
        //   'couponCode' : "",
        //   'priceBreakdown' : [],
        //   'isCartValid': false,
        //   'invalidMsg': ""
        // }}));
      }else{
        dispatch(setPaymentAck({data: {'status' : "error", 'order_id' : data.order_id, 'transaction_id' : data.transaction_id, 'amount': data.grand_total}})); 
        // dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED }));
      }
      dispatch(increasePhonepeStatusCheckCount());
      return true;
    })
    .catch((err) => {
      dispatch(setPaymentAck({data: {'status' : "error", 'order_id' : data.order_id, 'transaction_id' : data.transaction_id, 'amount': data.grand_total}})); 
      // dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED }));
      dispatch(increasePhonepeStatusCheckCount());
    });
  }else{
    dispatch(setPaymentAck({data: {'status' : "error", 'order_id' : data.order_id, 'transaction_id' : data.transaction_id, 'amount': data.grand_total}})); 
    // dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED }));
  }
}
