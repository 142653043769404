import { v4 as uuidv4 } from 'uuid';
import { APP_VERSION, DEVICE_TYPE } from "../constants/app_constants";
import { encrypt } from "./encryption_helper";

export async function getDeviceInfo(){

    try {
        // Fetch data from local storage
        const deviceInfoString = localStorage.getItem('device_info');

      if (deviceInfoString != null) {
        const deviceInfo = JSON.parse(deviceInfoString);
        // DeviceInfo deviceInfo = DeviceInfo.fromMap(jsonDecode(deviceInfoString) as Map<String, dynamic>);
        if (deviceInfo.token !== "123123" && deviceInfo.deviceId !== "") {
          return JSON.stringify(deviceInfo);
        }
      }
    } catch (e) {
      console.log(e);
    }
    
    try {
        const advertisingId = uuidv4().toString();
        console.log(advertisingId);
        let encryptedValue = "";
        if (advertisingId != null) {
            encryptedValue = encrypt(advertisingId);
        }

        const deviceInfo = {
            token: encryptedValue.toString(),
            device_id: advertisingId ?? "",
            app_version: APP_VERSION,
            device_type: DEVICE_TYPE,
        }

        // Store in local storage
        localStorage.setItem('device_info', JSON.stringify(deviceInfo));
        return JSON.stringify(deviceInfo);

    } catch (e) {
        console.log(e);
        console.log('Failed to get mac address.');

        return null;
    }

}