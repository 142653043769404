import { useEffect } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import Page from '../components/Page'; 
import { getLoginStatus } from '../store/user/reducer';
// sections
 

// ----------------------------------------------------------------------

export default function Settings() {
  const theme = useTheme();

  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => getLoginStatus(state));

  useEffect(() => { 
    if(!isLoggedIn){ 
      navigate('/');
    }
  },[isLoggedIn])

  return (
    <Page title="Settings">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
              Coming Soon!!
        </Typography>
 
      </Container>
    </Page>
  );
}
