import moment from 'moment';


export function getAge(dateString) 
{
    if(dateString!= null && dateString !== "") {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age-=1;
        }
        return age;
    
    }
    return " ";
    
}

export function formatDate(dateString, dateFormat="YYYY-MM-DD") 
{   
    return moment(new Date(dateString)).format(dateFormat);    
}