import { setUser } from '../../../store/user/reducer';
import { setSendOTPResponse, setVerifyOTPResponse } from '../../../store/otp/reducer';
import axios from '../../../utils/axios-configure'; 
import { API_SEND_OTP, API_VERIFY_OTP } from "../endpoints";
import { fetchUserDetails } from '../user/api';
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { OTP_ERROR, OTP_SUCCESS } from '../../../constants/message_constants';
import { getDeviceInfo } from '../../../utils/device_helper';

export const sendOTP = async (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));  

    let url = `${API_SEND_OTP}?mobile_no=${data.mobile}`;

    if(data.user_type){
        url = `${url}&user_type=${data.user_type}`
    }

    if(data.corporate_client_id){
        url = `${url}&corporate_client_id=${data.corporate_client_id}`
    }
    const deviceInfo = await getDeviceInfo();

    axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", "X-Device-Info": `${deviceInfo}`},
    })
    .then((response)=> {

        if(response.status === 200){
            dispatch(setSnackbarMessage({'message': OTP_SUCCESS.OTP_SEND_SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));             
            dispatch(setSendOTPResponse({data:response.data}));
        }else{
            dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_SEND_OTP , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));             
            dispatch(setSendOTPResponse({data: {}}));
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((error)=> {
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_SEND_OTP , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}

export const verifyOTP = (dispatch, cookies, formdata) => {
    const url = API_VERIFY_OTP;
    dispatch(setLoadingStatus({status: true}));  

    if(formdata.otp != null){
      const data = {
        "mobile_no" : formdata.mobile,
        "otp" : formdata.otp, 
        "referred_by" : formdata.referred_by
      }

      axios.post(url, data).then((response)=> { 
         
        if(response.status === 200){
            const responseData = response.data.data;
            dispatch(setVerifyOTPResponse({data:responseData}));

            if(responseData.success === true){ 
                // Get client details
                // fetchUserDetails(dispatch, cookies ); 
                // dispatch(setSnackbarMessage({'message': OTP_SUCCESS.OTP_VERIFY_SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS}));
                // dispatch(setSnackbarVisibilityStatus({'status': true }));             
                dispatch(setUser({data:responseData.user}));
                
            }else{
                dispatch(setSnackbarMessage({'message': OTP_ERROR.INCORRECT_OTP, 'severity': SNACKBAR_SEVERITY.ERROR}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));             
                dispatch(setLoadingStatus({status: false}));  
            }
        }else{
            dispatch(setVerifyOTPResponse({data: {}}));
            dispatch(setSnackbarMessage({'message': OTP_ERROR.INCORRECT_OTP, 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true })); 
            dispatch(setLoadingStatus({status: false}));  
        }

 
        }).catch((error)=> {
            dispatch(setVerifyOTPResponse({data: {}}));
            dispatch(setLoadingStatus({status: false})); 
            dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_VERIFY_OTP, 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));    
    
        });
    }else{
        dispatch(setVerifyOTPResponse({data: {}}));
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_VERIFY_OTP, 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
    }
}
