import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
import ProfileForm from '../sections/profile/ProfileForm'; 
import Navbar from '../components/Navbar';
// ----------------------------------------------------------------------

export default function Profile() {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Page title="My Profile" sx={{minHeight:'100vh'}}>
            <Grid container 
                padding={1.5} 
                flexDirection={'row'} 
                alignItems={'center'} 
                justifyContent={'flex-start'} 
                onClick={() => {
                    navigate('/menu');
                }}>
                    <Iconify icon={'eva:chevron-left-outline'} sx={{width: 24, height: 24}} />
                    <Typography variant="body1"> Go Back </Typography>    
            </Grid>
            
            <Grid container justifyContent={'space-evenly'} columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>
                {/* <Grid item lg={1.5} md={2.5} sm={0} xl={1.5}>
                    <Navbar />
                </Grid> */}
                <Grid item container lg={10} sm={12} md={9} xl={10} borderRadius={1} spacing={3} sx={{margin:'0!important',width:'100%!important'}}>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Typography variant={'h3'}>
                            {"My Profile"}
                        </Typography>
                    </Grid>
                    <Grid   
                        sx={{
                            padding: 5, 
                            width:'100%',
                            // textAlign: 'center',
                            // color: (theme) => theme.palette[color].darker,
                            // bgcolor: (theme) => theme.palette[color].lighter,
                            // ...sx,
                        }}
                        // {...other}
                    >
                        <ProfileForm />
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
}
