import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'

import userReducer from './user/reducer'
import clientReducer from './client/reducer'
import corporateReducer from './corporate/reducer'
import otpReducer from './otp/reducer'
import subscriptionReducer from './subscription/reducer'
import onboardingReducer from './onboarding/reducer'
import menuReducer from './menu/reducer'
import cartReducer from './cart/reducer'
import paymentReducer from './payment/reducer'
import orderReducer from './order/reducer' 
import layoutReducer from './layout/reducer' 
import feedbackReducer from './feedback/reducer' 
import aboutReducer from './about/reducer' 
import navigationReducer from './navigation/reducer' 

const reducers = combineReducers({
    about: aboutReducer,
    user: userReducer,
    client: clientReducer,
    corporate: corporateReducer,
    otp: otpReducer,
    subscription: subscriptionReducer,
    onboarding: onboardingReducer,
    menu: menuReducer,
    cart: cartReducer,
    payment: paymentReducer,
    layout: layoutReducer,
    feedback: feedbackReducer,
    order:orderReducer,
    navigation: navigationReducer
});
   
const persistConfig = {
    key: 'bytes-web-root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);
 
const store = configureStore({
    reducer: persistedReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }), 
})

export default store