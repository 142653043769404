import React, { useRef, useEffect, useState } from "react";
import { motion, useMotionValue, useAnimation } from "framer-motion";
import styled from "@emotion/styled";
import { Box, Button, Grid, Typography } from "@mui/material";

import Iconify from "../../../Iconify";

const StyledCard = styled(motion.div)`
  position: absolute;
`;

export const SwipeableCardComponent = ({ children, style, onVote, id, ...props }) => {
  // motion stuff
  const cardElem = useRef(null);

  const x = useMotionValue(0);
  const controls = useAnimation();

  const [constrained, setConstrained] = useState(true);

  const [direction, setDirection] = useState();

  const [velocity, setVelocity] = useState();

  const getVote = (childNode, parentNode) => {
    const childRect = childNode.getBoundingClientRect();
    const parentRect = parentNode.getBoundingClientRect();

    if (parentRect.left >= childRect.right){
        return false;
    }
    if (parentRect.right <= childRect.left) {
        return true;
    }

    return undefined;

    // const result =
    //   parentRect.left >= childRect.right
    //     ? false
    //     : ((parentRect.right <= childRect.left)
    //     ? true
    //     : undefined);
    // return result;
  };

  // determine direction of swipe based on velocity
  const getDirection = () => {
    if (velocity >=1 ){
        return "right";
    } 
    
    if(velocity <= -1){
        return "left";
    }

    return "undefined";
    // return velocity >= 1 ? "right" : (velocity <= -1 ? "left" : undefined);
  };

  const getTrajectory = () => {
    setVelocity(x.getVelocity());
    setDirection(getDirection());
  };

  const flyAwayDistance = (direction) => {
    const parentWidth = cardElem.current.parentNode.getBoundingClientRect()
      .width;
    const childWidth = cardElem.current.getBoundingClientRect().width;
    console.log(parentWidth);
    console.log(childWidth);
    
    return direction === "left"
      ? -parentWidth / 2 - childWidth / 2
      : parentWidth / 2 + childWidth / 2;
  };

  const flyAway = (min) => {
    

    if (direction && Math.abs(velocity) > min) {
      setConstrained(false);
      console.log('0--');
      controls.start({
        x: flyAwayDistance(direction)
      });
    }
  };

  const handleResponse = (direction) => {
    setConstrained(false);
    console.log('0--');
    const q = flyAwayDistance(direction)
    controls.start({
      x: q
    });
  }

  useEffect(() => {
    const unsubscribeX = x.onChange(() => {
      if (cardElem.current) {
        const childNode = cardElem.current;
        const parentNode = cardElem.current.parentNode;
        const result = getVote(childNode, parentNode);
        if(result !== undefined) {
            onVote(result);
        }
      }
    });

    return () => unsubscribeX();
  });

  const Item = styled.div`
    background: #f9fafb;
    width: 300px;
    height: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    text-shadow: 0 10px 10px #d1d5db;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    
  `

  return (
    <StyledCard
      animate={controls}
      dragConstraints={constrained && { left: 0, right: 0, top: 0, bottom: 0 }}
      dragElastic={1}
      ref={cardElem}
      style={{ x }}
      onDrag={getTrajectory}
      onDragEnd={() => flyAway(100)}
      // whileTap={{ scale: 1.1 }}
      {...props}
    >
      <Item data-value={'q'} >
        {children}
        <Box margin={2} />
        <Grid container flexDirection={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
          <Button onClick={() => {
            handleResponse("left");
            // setDirection("left");
            // setVelocity(1000);
            // flyAway(500);
          }}>
            <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} 
              style={{borderRadius:100, cursor:'pointer', background: 'red', 
                padding:10, height: '50px', width:'50px', boxShadow: '0px 2px 5px 3px #9e9e9e'}}
              >
                <Iconify icon={'eva:close-outline'} sx={{width: 32, height: 32, color: 'white'}} />
            </Grid>
          </Button>
          <Button onClick={() => {
            handleResponse("right");

            // setDirection("right");
            // setVelocity(1000);
            // flyAway(500);
          }}>
            <Grid container flexDirection={'column'} justifyContent={'center'}  alignItems={'center'} style={{borderRadius:100, cursor:'pointer', background: 'green', padding:10, height: '50px', width:'50px', boxShadow: '0px 2px 5px 3px #9e9e9e'}}>
                <Iconify icon={'eva:heart-fill'} sx={{width: 32, height: 32, color: 'white'}} />
            </Grid>
          </Button>
        </Grid>
      </Item>

      {/* {children} */}
    </StyledCard>
  );
};
