import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { getSession } from '../../data/network/session/api';
import { getLoginStatus, getUser, setActiveCorporateClient } from '../../store/user/reducer';
import { fetchUserDetails } from '../../data/network/user/api';
import Footer from '../../components/Footer';
import DashboardFooter from './DashboardFooter';
import Navbar from '../../components/Navbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden', 
  flexDirection: 'column',
  justifyContent: 'center',
});

const HeaderStyle = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '10vh',
  width: '128px',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MainContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100vh',
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(28),
    paddingRight: theme.spacing(2)
  }
}));



const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100vh',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({showHeader=true, showFooter=true}) {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => getLoginStatus(state));
  const userDetails = useSelector(state => getUser(state));

  useEffect(() =>{ 
    getSession(dispatch, cookies, removeCookie);
  },[])

  useEffect(() =>{ 
    if(!isLoggedIn){
      // navigate('/');
    }else{
      fetchUserDetails(dispatch, cookies);
    }
  },[isLoggedIn])

  useEffect(() =>{
    if(userDetails != null && userDetails.corporate_client != null){         
      const corporateClient = userDetails.corporate_client[0];
      dispatch(setActiveCorporateClient({client: corporateClient}))
    }
  },[userDetails])

  return (
    <RootStyle>
      <HeaderStyle>
        <a href='/'>
          <img src='../../static/logo.png' alt='Bytes' width={128} />
        </a>
      </HeaderStyle>
      <MainStyle>
        <MainContent>
          <Navbar />
          {/* {showHeader && <DashboardNavbar onOpenSidebar={() => setOpen(true)} />} */}
          {/* {showHeader && <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />} */}
          <Outlet />
          {/* {showFooter && <DashboardFooter />} */}
        </MainContent>
      </MainStyle> 
    </RootStyle>
  );
}
