import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {STATUS, PAYMENT_STATUS, PLAN_SELECTION_STATUS, PAYMENT_MODE, MEAL_TYPE,
  MEAL_PREFERENCE, ORDER_TYPE, CLIENT_MEAL_SCHEDULE_EDIT_STATUS,
  CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS
} from '../../constants/app_constants';

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientList: [],
    activeClient: null,
    clientDetailSubmitted: false,
    planSelectionStatus: PLAN_SELECTION_STATUS.INITIAL,
    selectedPlan: {},
    mealPreferenceDetails: {
      mealPreference: MEAL_PREFERENCE.VEGETARIAN,
      duration: 7,
      mealTypes: [MEAL_TYPE.BREAKFAST, MEAL_TYPE.LUNCH, MEAL_TYPE.SNACKS, MEAL_TYPE.DINNER ],
      startDate: formatDate(new Date()),
    },
    subscribedPlanDetails: null,
    subscriptionCartDetails : null,
    subscriptionCartTotalAmount : 0,
    paymentStatus : PAYMENT_STATUS.INITIAL,
    paymentMode: PAYMENT_MODE.OFFLINE,
    orderDetails: null,
    addTransactionDetails: null,
    orderType: ORDER_TYPE.SUBSCRIPTION,
    clientMealScheduleEditStatus: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL,
    mealSchedule: null,
    mealOptions: null,
    clientActivityScheduleEditStatus: CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS.INITIAL,
  },
  reducers: {
    setClientList(state, action) {
      state.clientList = action.payload.data;
    },
    setClientSubscriptionDetails(state, action) {
      state.subscribedPlanDetails = action.payload.data.subscription;
      state.subscriptionCartDetails = action.payload.data.cart;

      state.subscriptionCartDetails.price_breakup.forEach(element => {
          if(element.name === "grand_total"){
            state.subscriptionCartTotalAmount = element.value;
          }
      });
    },
    setActiveClient(state, action) {
      state.activeClient = action.payload.data;
    },
    setClientDetailSubmitted(state, action){
      state.clientDetailSubmitted = action.payload.status;
    },
    setClientPlanSelection(state, action){
      state.planSelectionStatus = action.payload.planSelectionStatus;
      state.selectedPlan = action.payload.selectedPlan;  
    },
    setClientMealPreference(state,action) {
      console.log(action.payload );
      if(action.payload.mealPreference !== undefined){
        state.mealPreferenceDetails.mealPreference =  action.payload.mealPreference; 
      }

      if(action.payload.mealTypes !== undefined){
        state.mealPreferenceDetails.mealTypes =  action.payload.mealTypes;         
      }

      if(action.payload.duration !== undefined){
        state.mealPreferenceDetails.duration =  action.payload.duration;         
      }

      if(action.payload.startDate !== undefined){
        state.mealPreferenceDetails.startDate =  action.payload.startDate;         
      }
    },
    setPaymentStatus(state, action){
      state.paymentStatus = action.payload.status;
    },
    setPaymentMode(state, action){
      state.paymentMode = action.payload.mode;
    },
    setClientOrderDetails(state, action){
      state.orderDetails = action.payload.data;
    },
    setClientOrderType(state, action){
      state.orderType = action.payload.type;
    },
    setAddTransactionDetails(state, action){
      state.addTransactionDetails = action.payload.data;
    },
    resetClientState(state, action){
      state.activeClient = null;
      state.clientDetailSubmitted = false;
      state.planSelectionStatus = PLAN_SELECTION_STATUS.INITIAL;
      state.selectedPlan = {};
      state.paymentStatus = PAYMENT_STATUS.INITIAL;
      state.paymentMode = PAYMENT_MODE.OFFLINE;
      state.subscribedPlanDetails = null;
      state.subscriptionCartDetails = null;
      state.subscriptionCartTotalAmount = 0;
      state.orderDetails = null;
      state.addTransactionDetails = null;
      state.mealPreferenceDetails = {
        mealPreference: MEAL_PREFERENCE.VEGETARIAN,
        duration: 7,
        mealTypes: [MEAL_TYPE.BREAKFAST, MEAL_TYPE.LUNCH, MEAL_TYPE.SNACKS, MEAL_TYPE.DINNER ],
        startDate: formatDate(new Date()),
      };
    },
    setClientMealScheduleEditStatus(state, action) {
      state.clientMealScheduleEditStatus = action.payload.status;
    },
    setClientMealSchedule(state,action){
      if(action.payload.status === STATUS.SUCCESS){
        state.mealSchedule = action.payload.data;
      }
    },
    setClientMealOptions(state,action){
      if(action.payload.status === STATUS.SUCCESS){        
        state.mealOptions = action.payload.data;
      }
    },
    setClientActivityScheduleEditStatus(state, action) {
      state.clientActivityScheduleEditStatus = action.payload.status;
    }
  }
})

export const getClientList = (state) => {

  if(state.client != null && state.client !== undefined){
    return state.client.clientList;
  }
  return [];
}

export const getActiveClient = (state) => {

  if(state.client != null && state.client !== undefined){
    return state.client.activeClient;
  }
  return null;
}

export const getClientDetailStatus = (state) => state.client.clientDetailSubmitted

export const getClientPlanSelectionStatus = (state) => state.client.planSelectionStatus

export const getClientSelectedPlan = (state) => state.client.selectedPlan

export const getClientPaymentStatus = (state) => state.client.paymentStatus

export const getClientPaymentMode = (state) => state.client.paymentMode

export const getClientOrderDetails = (state) => state.client.orderDetails

export const getAddTransactionDetails = (state) => state.client.addTransactionDetails;

export const getClientSubscriptionPlanDetails = (state) => state.client.subscribedPlanDetails;

export const getClientSubscriptionCartDetails = (state) => state.client.subscriptionCartDetails;

export const getClientSubscriptionCartTotalAmount = (state) => state.client.subscriptionCartTotalAmount;

export const getClientMealPreference  = (state) => state.client.mealPreferenceDetails;

export const getClientOrderType  = (state) => state.client.orderType;

export const getClientMealScheduleEditStatus = (state) => state.client.clientMealScheduleEditStatus;

export const getClientMealSchedule = (state) => state.client.mealSchedule;

export const getClientMealOptions = (state) => state.client.mealOptions;

export const getClientActivityScheduleEditStatus = (state) => state.client.clientActivityScheduleEditStatus;

export const { setClientList, setActiveClient, setClientSubscriptionDetails, 
  setClientPlanSelection, setClientDetailSubmitted,setClientMealPreference,
  setPaymentStatus, setPaymentMode, setClientOrderDetails, setAddTransactionDetails, 
  resetClientState, setClientOrderType, setClientMealScheduleEditStatus, setClientMealSchedule, 
  setClientActivityScheduleEditStatus, setClientMealOptions
} = clientSlice.actions

export default clientSlice.reducer