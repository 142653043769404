import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import { getFeedbackComponentVisibility, setFeedbackComponentVisibility } from '../../store/feedback/reducer';
import FeedbackModal from './feedback_modal';

// Card component with destructured props
export default function FeedbackComponent() {

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const isVisible = useSelector(state => getFeedbackComponentVisibility(state));

    const handleClose = () => {
        dispatch(setFeedbackComponentVisibility({ isVisible: false}));
    }

    

    return (
        <div className='feedback-main-container'>
            <FeedbackModal isOpen={isVisible} handleClose={handleClose} />
        </div>
    );
};
 