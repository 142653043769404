import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Link, Container, Typography, Card, Stack, Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import {useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';
import { subDays } from 'date-fns';
import $ from 'jquery';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// components
import Page from '../components/Page'; 
import { formatDate } from '../utils/date';
import { MyOrderList } from '../sections/myorders';
// sections

import { fetchUserCorporateOrders } from '../data/network/user/api';
import { getLoginStatus } from '../store/user/reducer';


// ----------------------------------------------------------------------

export default function MyCorporateOrders() {


  const theme = useTheme();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const isLoggedIn = useSelector(state => getLoginStatus(state));

  useEffect(() => { 
    if(!isLoggedIn){ 
      navigate('/');
    }
  },[isLoggedIn])

  const [filterSelection, setFilterSelection] = useState([{
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
    key: 'selection'
  }]);

  const handleDateFilterSelection = (selectedDateRange) => {
    setFilterSelection([selectedDateRange]); 
  }

  useEffect(() => {
    fetchUserCorporateOrders(dispatch, cookies, {
      'start_date' : formatDate(filterSelection[0].startDate),
      'end_date' : formatDate(filterSelection[0].endDate)
    });
  },[])


  return (
    <Page title="My Orders">
      <Container maxWidth="xl">
      <Card sx={{padding: 4 }}  className="glass-box">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h5" gutterBottom>
                  Filters
              </Typography>
              
              <Link href="#" underline="none">
                RESET
              </Link> 
          </Stack>
          <Grid className="user-my-orders-filter-container" container direction="row" justifyContent="start" padding={2} >
            {/* <FormGroup>
              <FormLabel >Meal Types</FormLabel>
              <Box padding={1} />
              <FormControlLabel control={<Checkbox defaultChecked/>} label="Breakfast" />
              <FormControlLabel control={<Checkbox defaultChecked/>} label="Lunch" />
              <FormControlLabel control={<Checkbox defaultChecked/>} label="Snacks" />
              <FormControlLabel control={<Checkbox defaultChecked/>} label="Dinner" />
            </FormGroup> */}
           {  
            <Grid item container direction="row" alignItems="center" >
              <div><b>Date Range</b></div>
              <Box margin={2} />        
              { 
              open 
              ? 
                <Card className="glass-box">

                <DateRangePicker 
                onChange={item => handleDateFilterSelection(item.selection)}
                showSelectionPreview 
                defined
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={filterSelection}
                direction="vertical"
              />
                </Card>
              : <Box>
                  <span>
                  {`  
                    ${formatDate(filterSelection[0].startDate, "DD MMM YYYY")}  
                     to  
                    ${formatDate(filterSelection[0].endDate, "DD MMM YYYY")}
                  `
                  } 
                  </span>
                  
              </Box>
              
            } 
            
              <Box margin={1} />
              {
              (!open)
              ? <Button variant='outlined' onClick={() =>{
                  setOpen(true);
                }}>Change
              </Button>
              : null
            } 
            </Grid>

          }
          </Grid>
          
          { 
            (open)
            ? <Button variant='contained' onClick={() =>{
                setOpen(false);
                $('html, body').animate({
                  scrollTop: $("#user-my-orders-data-container").offset().top
                }, 500);
              }}>Apply
            </Button>
          : null
          }
        </Card>
 
        <Card sx={{padding: 4, marginTop: 4}}  className="glass-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    My Orders
                </Typography> 
            </Stack>
            <MyOrderList />
        </Card>
        
      </Container>
    </Page>
  );
}
