// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Grid, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AccountTypeHeadWidget.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  sx: PropTypes.object,
};

export default function AccountTypeHeadWidget({ title, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      className='glass-box'
      sx={{
        py: 5,
        px: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
        <Grid container justifyContent="center"  spacing={1}>
            <Grid item container justifyContent="center" padding={1} >
                <Typography variant="h5" sx={{ opacity: 0.72 }}>
                    Hey You!
                </Typography>
            </Grid>
            <Grid item container justifyContent="center" padding={1}  >
                <Typography variant="h4" sx={{ opacity: 0.72 }}>
                    Welcome to Bytes!
                </Typography>
            </Grid>
            <Grid item container justifyContent="center" padding={1}  >
                <Typography variant="h6" sx={{ opacity: 0.72 }}>
                    The Delicious Project
                </Typography>
            </Grid>
        </Grid>
    </Card>
  );
}
