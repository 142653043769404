import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {STATUS, PAYMENT_STATUS, PLAN_SELECTION_STATUS, PAYMENT_MODE, MEAL_TYPE,
  MEAL_PREFERENCE, ORDER_TYPE, CLIENT_MEAL_SCHEDULE_EDIT_STATUS,
  CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS
} from '../../constants/app_constants';

const corporateSlice = createSlice({
  name: 'corporate',
  initialState: {
    corporateList: [],   
  },
  reducers: {
    setCorporateList(state, action) {
      state.corporateList = action.payload.data;
    },
    resetCorporateState(state, action){
      state.corporateList = []; 
    }, 
  }
})

export const getCorporateList = (state) => {

  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.corporateList;
  }
  return [];
}


 
export const { setCorporateList  } = corporateSlice.actions

export default corporateSlice.reducer