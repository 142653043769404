import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {SNACKBAR_SEVERITY } from '../../constants/app_constants';

const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    loadingStatus: false,
    snackbarVisibilityStatus: false,
    snackbarMessage: null,
    snackbarSeverity: SNACKBAR_SEVERITY.INFO, 
  },
  reducers: {
    setLoadingStatus(state, action) {
        state.loadingStatus = action.payload.status;
    },
    setSnackbarVisibilityStatus(state, action) {
      state.snackbarVisibilityStatus = action.payload.status;
    },
    setSnackbarMessage(state, action) {
        state.snackbarMessage = action.payload.message;
        state.snackbarSeverity = action.payload.severity;
    }, 
    resetlayoutState(state, action){
      state.loadingStatus = false;
      state.snackbarVisibilityStatus = false;
      state.snackbarMessage =  null;
      state.snackbarSeverity = SNACKBAR_SEVERITY.INFO;
    }, 
  }
})

export const getLoadingStatus = (state) => {

    try{
        return state.layout.loadingStatus;
    }catch(e){
        return false;
    } 
}


export const getSnackbarVisibilityStatus = (state) => {

    try{
        return state.layout.snackbarVisibilityStatus;
    }catch(e){
        return false;
    } 
}


export const getSnackbarMessage = (state) => {

    try{
        return state.layout.snackbarMessage;
    }catch(e){
        return false;
    } 
}


export const getSnackbarSeverity = (state) => {

    try{
        return state.layout.snackbarSeverity;
    }catch(e){
        return false;
    } 
} 
 
export const { setLoadingStatus, setSnackbarVisibilityStatus, setSnackbarMessage,
    setSnackbarSeverity, 
} = layoutSlice.actions

export default layoutSlice.reducer