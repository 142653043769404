import { setLoadingStatus } from '../../../store/layout/reducer';
import { updateCart } from '../../../store/cart/reducer';
import axios from '../../../utils/axios-configure';
import { API_GET_CORPORATE_LIST  } from "../endpoints";
import { setCorporateList } from '../../../store/corporate/reducer';


export const fetchCorporateList = (dispatch) => {  
    dispatch(setLoadingStatus({status: true}));  
    axios({
      method: "get",
      url: API_GET_CORPORATE_LIST,
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
        if(response.status === 200){ 
            dispatch(setCorporateList({data: response.data.data}));
        }else{ 
            dispatch(setCorporateList({data: []}));
        }
        dispatch(setLoadingStatus({status: false}));  
    })
    .catch((response) => {  
        dispatch(setCorporateList({data: [ ]})); 
        dispatch(setLoadingStatus({status: false}));  
    });
  }

