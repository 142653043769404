import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components
import Page from '../components/Page'; 
// sections
 

// ----------------------------------------------------------------------

export default function Nutritionist() {
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Page title="Nutritionist">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
              Coming Soon!!
        </Typography>
 
      </Container>
    </Page>
  );
}
