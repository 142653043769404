 // @mui
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled  } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Container, Grid, TextField, Typography  } from '@mui/material'; 
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AnimatePresence, motion } from 'framer-motion';

import { getSelectedAccountType, getSelectedCorporate } from '../../store/otp/reducer';
import { fetchMenu } from '../../data/network/menu/api';
import { formatDate } from '../../utils/date';
import { ACCOUNT_TYPE } from '../../constants/app_constants';
import { getCategoryList, getItemList, getMenuList } from '../../store/menu/reducer';
import MenuItemCard from './MenuItemCard';
import Iconify from '../../components/Iconify';
 // components
  
 // ----------------------------------------------------------------------
  
 export default function MenuList() {

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const selectedAccountType = useSelector(state => getSelectedAccountType(state));
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));
    const menuListOptions = useSelector(state => getMenuList(state));
    const categoryListOptions = useSelector(state => getCategoryList(state));
 
    const [expanded, setExpanded] = useState(false);
    const [counter, setCounter] = useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (categoryListOptions && categoryListOptions.length > 0 && counter === 0){
            const name = categoryListOptions[0].category_name;
            setExpanded(`${name}`);
            setCounter(counter+1);
        }
    },[categoryListOptions]);

    return (
        <Grid container className="menu-list-container">        
            {
                menuListOptions && menuListOptions.length > 0
                ? menuListOptions.map((e,j) => 
                    e.items.length > 0 && 
                        <Grid key={j} item  marginBottom={1} style={{paddingLeft:'2px', paddingRight:'2px'}} container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="flex-start"> 
                            <Accordion className="menu-category-accordion glass-box" expanded={expanded === `${e.category_name}`} onChange={handleChange(`${e.category_name}`)} sx={{width:'100%'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    sx={{ margin: '0!important'}}
                                >
                                    <Typography id={`category-heading-${e.category_name}`} variant={'h5'}>{e.category_display_name} {`(${e.items.length})`}</Typography>
                                </AccordionSummary>
                                <AccordionDetails className='menu-list-accordion-detail'>
                                    <Grid container rowGap={2} columnGap={2}>
                                        {
                                            e.items.map((menuItem,i) => 
                                                <MenuItemCard key={i} item = {menuItem}  />
                                            )
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                ) 
                : 
                <Grid container direction='column' justifyContent="center" alignItmes='center' sx={{width:'100%',height:'30vh'}}> 
                    <Typography  sx={{textAlign: 'center'}}>No items found</Typography>
                </Grid>
            } 

        </Grid>
   );
 }
 