import { useState , useEffect} from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card, Box, Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// components
import Page from '../components/Page'; 
import Navbar from '../components/Navbar';
import Iconify from '../components/Iconify';
import { CHECKOUT_TYPE } from '../constants/app_constants';
import { quickCheckoutAddToCart } from '../data/network/cart/api';
import { OrderSummary } from '../sections/menu';
import { ReactComponent as Loader } from "../assets/img/loader.svg";
import { setAfterLoginUrl } from '../store/navigation/reducer';
import { getLoginStatus } from '../store/user/reducer';

// sections

export default function QuickCheckout() {
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

  const scheduleId = searchParams.get('schedule_id');
  const addOns = searchParams.get('add_ons');
  const addOnQty = searchParams.get('add_on_qty');
  const quantity = searchParams.get('quantity');

  const isLoggedIn = useSelector(state => getLoginStatus(state));

  useEffect(() => {
    try{
      const url = window.location.href;
      // check login status
      if (!isLoggedIn) {
        dispatch(setAfterLoginUrl({'url': url }));
        navigate('/');
      }else{

        const cartData = {
            'schedule_id': parseInt(scheduleId, 10),
        }

        if (quantity) {
            cartData.quantity = parseInt(quantity, 10)
        } else {
            cartData.quantity = 1
        }
        cartData.add_ons = []

        if (addOns) {
          const addOnList = addOns.split(',')
          
          if (addOnList.length > 0) {
            if (addOnQty) {
              const addOnQtyList = addOnQty.split(',')
              for (let i = 0; i< addOnList.length; i+=1) {
                try{
                  cartData.add_ons.push({
                    'schedule_id': parseInt(addOnList[i], 10),
                    'quantity' : parseInt(addOnQtyList[i], 10)
                  })
                }catch(e){
                  console.log('Invalid addon configuration');
                }
              }
            }
          }
        }
        cartData.checkout_type = CHECKOUT_TYPE.QUICK_CHECKOUT
        console.log(cartData);
        

        // call add to cart api
        try{
          quickCheckoutAddToCart(dispatch, cookies, cartData);

          setTimeout(() => {
            navigate('/cart')
          },1500);
        }catch(e) {
          console.log(e);
        }
      }

    }catch(err){
        console.log(err);
    }
  },[]);


  return (
    <Page title="Checkout" sx={{minHeight:'100vh'}}>
            <Grid container justifyContent={'space-evenly'} columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>
                <Grid container 
                    padding={1.5} 
                    flexDirection={'row'} 
                    alignItems={'center'} 
                    justifyContent={'flex-start'} 
                    onClick={() => {
                        navigate('/menu');
                    }}>
                        <Iconify icon={'eva:chevron-left-outline'} sx={{width: 24, height: 24}} />
                        <Typography variant="body1"> Go Back </Typography>    
                </Grid>
                <Grid item container lg={10} sm={12} md={9} xl={10} borderRadius={1} spacing={3} sx={{margin:'0!important',width:'100%!important'}}>
                    
                <Container maxWidth="xl" height="100vh">
                  <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Typography variant={'h3'}>
                            {"Fetching you order details..."}
                        </Typography>
                  </Grid>
                  <Grid sx={{padding:5}}>
                    {/* <ContactInfo />
                    <Box margin={5} />
                    <ContactForm /> */}

                    {/* <OrderSummary /> */}
                    
                    <Loader />
                  </Grid>
          
                </Container>
            </Grid>
          </Grid>
    </Page>
  );
}
