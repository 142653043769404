import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {motion} from 'framer-motion';
import { OrderSummary } from "../sections/menu";
import Navbar from "../components/Navbar";
import Page from "../components/Page";
import Iconify from "../components/Iconify";

export default function Cart() {
    const navigate = useNavigate();
    return (
        <Page title="Cart" sx={{minHeight:'100vh'}}>
            <Grid container className="header" flexDirection={'row'} justifyContent={'flex-start'}>
                <Grid container item sm={3} md={3} xs={3} lg={3} xl={3}
                    padding={1.5} 
                    flexDirection={'row'} 
                    alignItems={'center'} 
                    justifyContent={'flex-start'} 
                    onClick={() => {
                        navigate('/menu');
                    }}>
                        <Iconify icon={'eva:chevron-left-outline'} sx={{width: 32, height: 32, color: '#fff'}} />
                        {/* <Typography variant="body1"> Go Back </Typography>     */}
                </Grid>

                <Grid container item justifyContent={'center'} alignItems={'center'}  sm={6} md={6} xs={6} lg={6} xl={6}>
                    <Typography variant={'h4'} sx={{color:'#fff'}}>
                        {"Order Summary"}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent={'space-evenly'} marginTop={6} paddingTop={4}  columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>
                <Grid item lg={10} sm={12} md={9} xl={10} xs={12} borderRadius={1} sx={{overflowY:'scroll'}}>
                    <Grid item>
                        <OrderSummary />
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    )
}