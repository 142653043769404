import { createSlice } from '@reduxjs/toolkit'  

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
      cartItems : [],
      couponCode : "",
      priceBreakdown : [],
      isCartValid: false,
      invalidMsg: "",
      paymentModes : [],
      selectedDeliveryPreference: null,
      deliveryInstruction: "",
      socketUrl : null,
  },
  reducers: { 
    updateCart(state, action) {
        const {data} = action.payload; 
        state.cartItems = data.items;
        state.priceBreakdown = data.price_breakup;
        state.isCartValid = data.is_valid;
        state.invalidMsg = data.invalid_message; 
        state.paymentModes = data.payment_modes;
    },
    resetCart(state,action) {
        state.cartItems = [];
        state.couponCode = "";
        state.priceBreakdown = [];
        state.isCartValid = false;
        state.invalidMsg = "";
        state.paymentModes = [];
        state.selectedDeliveryPreference = null;
        state.deliveryInstruction = "";
        state.socketUrl = "";
    },
    setDeliveryPreference(state, action) {
        console.log(action.payload);
        state.selectedDeliveryPreference = action.payload.preference;
    },
    setDeliveryInstruction(state, action) {
        console.log(action.payload);
        state.deliveryInstruction = action.payload.instruction;
    },
    setSocketUrl(state, action) {
        state.socketUrl = action.payload.url;
    }
  }
})

export const getDeliveryPreference = (state) => {
    try{
        return state.cart.selectedDeliveryPreference;
    }catch(e){
        console.log(e);
        return "";
    }
}

export const getDeliveryInstruction = (state) => {
    try{
        return state.cart.deliveryInstruction;
    }catch(e){
        console.log(e);
        return "";
    }
}

export const getPaymentModes = (state) => {
    try{
        return state.cart.paymentModes;
    }catch(e){
        console.log(e);
        return [];
    }
}

export const getItemQty = (state, item ) => {

    try{
        if(state.cart && state.cart.length !== 0){
            const cartItem = state.cart.cartItems.filter(cartItem => cartItem.schedule_id === item.schedule_id);
            let qty = 0;
            cartItem.forEach(item => {
                qty += item.quantity
            })
            return qty;            
        }
    }catch (e){
        return 0;
    }
    return 0;
}

export const totalCartItem = (state) => {
    try{

        if(state.cart && state.cart.length !== 0){
            let count = 0;
            state.cart.cartItems.forEach(item => {
                if(item.quantity){ 
                    count += item.quantity;
                }
            });

            return count;
        }
    }catch (e){
        return 0;
    }
    return 0;
    
}

export const totalCartAmount = (state) => {
    try{

        if(state.cart && state.cart.length !== 0){
            let amount = 0;
            state.cart.cartItems.forEach(cartItem => {
                if(cartItem.quantity){
                    amount += (cartItem.effective_price)*(cartItem.quantity);
                }
            });

            return amount;
        }
    }catch (e){
        return 0;
    }
    return 0;
    
}

export const getCartItems = (state) => {
    try{

        if(state.cart && state.cart.length !== 0){
            return state.cart.cartItems;
        }
    }catch (e){
        return [];
    }
    return [];
    
}

export const getCartItemsById = (state, item) => {
    try{
        if(state.cart && state.cart.cartItems && state.cart.cartItems.length !== 0){
            const cartItem = state.cart.cartItems.filter(cartItem => cartItem.schedule_id === item.schedule_id);
            return cartItem;
        }
    }catch (e){
        console.log(e);
        return [];
    }
    return [];
}

export const getPriceBreakdown = (state) => {
    try{

        const priceBreakdown = [];
        if(state.cart && state.cart.length !== 0){

            state.cart.priceBreakdown.forEach(e => {
                if(e.name !== "grand_total"){
                    priceBreakdown.push(e);
                }
            });
        }
        return priceBreakdown;
    }catch (e){
        return [];
    }
    
}

export const getGrandTotal = (state) => {
    try{

        let grandTotal = 0;
        if(state.cart && state.cart.length !== 0){

            state.cart.priceBreakdown.forEach(e => {
                if(e.name === "grand_total"){
                    grandTotal = e.value;
                }
            });

        }
        return grandTotal;
    }catch (e){
        return 0;
    }
    
}

export const isCartValid = (state) => {
    try{

        if(state.cart && state.cart.length !== 0){
            return state.cart.isCartValid;
        }
    }catch (e){
        return false;
    }
    return false;
        
}

export const getInvalidCartMessage = (state) => {
    try{

        if(state.cart && state.cart.length !== 0){
            return state.cart.invalidMsg;
        }
    }catch (e){
        return false;
    }
    return false;
    
}

export const getSocketUrl = (state) => {
    try{
        return state.cart.socketUrl;
    }catch(e){
        return null;
    }
}

export const {  updateCart, setDeliveryPreference, setDeliveryInstruction, resetCart, setSocketUrl } = cartSlice.actions
export default cartSlice.reducer